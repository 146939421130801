export const GET_TRANSACTION = "REQUIREMENTS/GET_TRANSACTION";
export const GOT_TRANSACTION = "REQUIREMENTS/GOT_TRANSACTION";

export const GET_REQUIREMENTS = "REQUIREMENTS/GET_REQUIREMENTS";
export const GOT_REQUIREMENTS = "REQUIREMENTS/GOT_REQUIREMENTS";

export const GET_ATTACHMENT = "REQUIREMENTS/GET_ATTACHMENT";
export const GOT_ATTACHMENT = "REQUIREMENTS/GOT_ATTACHMENT";

export const UPLOAD_ATTACHMENT = "REQUIREMENTS/UPLOAD_ATTACHMENT";

export const PROCEED_TO_APPOINTMENT = "REQUIREMENTS/PROCEED_TO_APPOINTMENT";

export const UPLOAD_EXISTING_ATTACHMENT =
  "REQUIREMENTS/UPLOAD_EXISTING_ATTACHMENT";

export const CLEAR_STATE = "REQUIREMENTS/CLEAR_STATE";

export const GET_MORE_DETAILS = "REQUIREMENTS/GET_MORE_DETAILS";
export const GOT_MORE_DETAILS = "REQUIREMENTS/GOT_MORE_DETAILS";

export const PAYMENT_INITIATION = "REQUIREMENTS/PAYMENT_INITIATION";

export const UPLOAD_RECEIPT = "REQUIREMENTS/UPLOAD_RECEIPT";
export const UPLOADED_RECEIPT = "REQUIREMENTS/UPLOADED_RECEIPT";

export const GENERATE_PERMIT = "REQUIREMENTS/GENERATE_PERMIT";