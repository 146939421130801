import { takeEvery, put, call, all } from "redux-saga/effects";
import * as c from "./constant";

import {
  loading,
  alert,
  history,
  services,
  watchApiReponse
} from "../../Utils";

function* getVenues() {
  yield put(loading("REQUEST_APPLICANT"));

  const url = `/ste/application/transaction/exam/venues`;

  const response = yield call(services.get(url), {});
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_VENUES,
      data
    });
  });
}

function* getExaminationScheduleList({ schedule_type, txnid, exam_venue_id }) {
  yield put(loading("GET_EXAMINATION_LIST"));

  const url = `/ste/payment/schedule/exam`;

  const response = yield call(services.get(url), {
    schedule_type,
    txnid,
    exam_venue_id
  });

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_EXAMINATION_SCHEDULE_LIST,
      data
    });
  });
  yield put(loading("GET_EXAMINATION_LIST", false));
}

function* getTransaction({ txnid }) {
  yield put(loading("SET_ONLINE_SCHEDULE/GET_TRANSACTION"));

  const response = yield call(services.get(`/ste/payment/${txnid}`), {});

  yield put(loading("SET_ONLINE_SCHEDULE/GET_TRANSACTION", false));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: "SET_ONLINE_SCHEDULE/GOT_TRANSACTION",
      data
    });
  });
}

function* setExaminationOnlineSchedule({ exam_schedule_id, txnid }) {
  yield put(loading("SET_ONLINE_SCHEDULE/SET_EXAMINATION_ONLINE_SCHEDULE"));

  const url = `/ste/payment/schedule/exam`;

  const response = yield call(services.post(url), {
    exam_schedule_id,
    txnid
  });

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;
    alert.success(message);

    yield put({
      type: "MODAL",
      data: {
        isOpen: false
      }
    });
  });

  yield put(loading("SET_ONLINE_SCHEDULE/SET_EXAMINATION_ONLINE_SCHEDULE", false));

  history.push(`/main/application`);
}

export default function* profileSaga() {
  yield all([
    takeEvery(`SET_ONLINE_SCHEDULE/GET_VENUES`, getVenues),
    takeEvery(
      `SET_ONLINE_SCHEDULE/GET_EXAMINATION_SCHEDULE_LIST`,
      getExaminationScheduleList
    ),
    takeEvery(`SET_ONLINE_SCHEDULE/GET_TRANSACTION`, getTransaction),
    takeEvery(
      `SET_ONLINE_SCHEDULE/SET_EXAMINATION_ONLINE_SCHEDULE`,
      setExaminationOnlineSchedule
    )
  ]);
}
