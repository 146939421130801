import * as c from './constant';
import { Map, fromJS, List } from 'immutable';
import { _ } from 'app/Utils';

const INIT_STATE = Map({
  programs: List([]),
  schools: List([]),
  rank: List([]),
  vessel_type: List([]),
  profile: Map({}),
  form_data: Map(c.FORM_DATA),
});

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case c.UPDATED_PHOTO:
      return state.setIn([`profile`, `photo`], action.photo);

    case c.GOT_ME:
      const data = {
        ...action.profile,
        barangay_code: !_.isNil(action.profile.barangay)
          ? action.profile.barangay.code
          : '',
        barangay_name: !_.isNil(action.profile.barangay)
          ? action.profile.barangay.name
          : '',
        province_code: !_.isNil(action.profile.province)
          ? action.profile.province.code
          : '',
        province_name: !_.isNil(action.profile.province)
          ? action.profile.province.name
          : '',
        region_code: !_.isNil(action.profile.region)
          ? action.profile.region.code
          : '',
        region_name: !_.isNil(action.profile.region)
          ? action.profile.region.name
          : '',
        municipality_code: !_.isNil(action.profile.municipality)
          ? action.profile.municipality.code
          : '',
        municipality_name: !_.isNil(action.profile.municipality)
          ? action.profile.municipality.name
          : '',
        birth_date: !_.isNil(action.profile.birth_date)
          ? action.profile.birth_date
          : '',
      };

      return state
        .set('profile', fromJS(data))
        .set('form_data', fromJS(_.pick(data, _.keys(c.FORM_DATA))));

    case c.GOT_PROGRAM:
      return state.set('programs', fromJS(action.data));

    case c.GOT_RANK:
      return state.set(`rank`, fromJS(action.data));

    case c.GOT_VESSEL_TYPE:
        return state.set(`vessel_type`, fromJS(action.data));

    case c.GOT_SCHOOL:
      return state.set('schools', fromJS(action.data));

    case c.SET_FORM_DATA:
      return state.update(`form_data`, form_data =>
        form_data.merge(action.data)
      );

    default:
      return state;
  }
};
