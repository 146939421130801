import React, { PureComponent } from 'react';
import RModal from 'react-modal';

export const Body = ({ children }) => (
    <div className="modal-body">
        { children }
    </div>
)

export const Footer = ({ children }) => (
    <div className="modal-footer">
        { children }
    </div>
)

export const Confirmation = ({ onYes, onNo, yesLabel, noLabel }) => {

    return(
        <div className="modal-body text-right">
            <button className="btn btn-danger" onClick={ onYes }>{ yesLabel || "Yes" }</button>
            { ' ' }
            <button className="btn btn-secondary" onClick={ onNo }>{ noLabel || "No"}</button>
        </div>
    )
}

class Modal extends PureComponent {

    static defaultProps = {
        isOpen: false,
        title: 'Modal Title',
        modalSize: 'modal-md'
    }

    render() {
        const { onClose, children, data } = this.props;

        const rawData =  data.toJS();

        return (
            <RModal
              className={`Modal__Bootstrap ${rawData.modalSize || this.props.modalSize} modal-dialog`}
              closeTimeoutMS={10} //150
              contentLabel=""
              isOpen={rawData.isOpen}
              onRequestClose={onClose}>
                <div className="modal-content">
                    { !rawData.hideHeader &&
                        <div className="modal-header">
                             <h4 className="modal-title">{rawData.isOpen ? rawData.title : null }</h4>
                             <button type="button" className="close" onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                                <span className="sr-only">Close</span>
                             </button>
                        </div>
                    }
                    {rawData.isOpen ? children : null }
                </div>
            </RModal>
        );
    }
}

Modal.Body = Body
Modal.Footer = Footer
Modal.Confirmation = Confirmation

export default Modal