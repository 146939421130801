import * as c from './constant';
import { Map, fromJS } from 'immutable';
import { _ } from 'app/Utils';

const initState = Map({
	assessment_center_profile: Map({}),
	form_data: Map(c.FORM_DATA),
})

export default (state = initState, action) => {
	switch(action.type){
		
		case c.GOT_ASSESSMENT_CENTER_PROFILE:
			return state
				.set('assessment_center_profile', fromJS(action.data))
				.set('form_data', fromJS(_.pick(action.data, _.keys(c.FORM_DATA))))

		case c.SET_FORM_DATA:
            return state.update('form_data',form_data => form_data.merge(fromJS(action.data)));

		default:
			return state;
	}
}
