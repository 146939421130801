import React from 'react';
import { Redirect, Route/*, Link*/ } from 'react-router-dom';

import { AsyncComponent } from './Utils';

const HomeContainer = AsyncComponent(() => import('./modules/landing/container/HomeContainer'))
const SignUpContainer = AsyncComponent(() => import('./modules/auth/container/SignUpContainer'))
const RegisterType = AsyncComponent(() => import('./modules/auth/container/RegisterType'));
const VerifyCertificateOrID = AsyncComponent(() => import('./modules/auth/container/VerifyCertificateOrID'));
const SignInContainer = AsyncComponent(() => import('./modules/auth/container/SignInContainer'))
const ForgotPassword = AsyncComponent(() => import('./modules/auth/container/ForgotPassword'))
const ResetPassword = AsyncComponent(() => import('./modules/auth/container/ResetPassword'))
const VerifyContainer = AsyncComponent(() => import('./modules/auth/container/VerifyContainer'))
const RegisterNewAccount = AsyncComponent(() => import('./modules/auth/container/RegisterNewAccount'))
const ResendVerification = AsyncComponent(() => import('./modules/auth/container/ResendVerification'))
const MainContainer = AsyncComponent(() => import('./modules/main/container/MainContainer'))
const Schedule = AsyncComponent(() => import('./modules/schedule/container'));

const VerifyQRCode = AsyncComponent(() => import ('./modules/auth/container/VerifyQRCode'));

const PrivateRoute = ({ component: Component, ...rest }) => {

    return (
          <Route {...rest} render={props => (
            rest.isAuthenticated ? (
                <Component {...props}/>
            ) : (
                <Redirect to={{ pathname: '/signin', state: { from: props.location }}}/>
            )
        )}/>
    )
}


export default ({ isAuthenticated }) => ([
    <Route key={`home`} exact path="/" component={ HomeContainer }/>,
    <Route key={`signup`} path="/signup" component={ SignUpContainer }/>,
    <Route key={`register-type`} path="/register-type" component={ RegisterType }/>,
    <Route key={`register-new`} path="/register-new" component={ RegisterNewAccount }/>,
    <Route key={`/verify-id-certificate`} path="/verify-id-certificate" component={ VerifyCertificateOrID }/>,
    <Route key={`signin`} path="/signin" component={ SignInContainer }/>,
    <Route key={`forgot`} path="/forgot-password" component={ ForgotPassword }/>,
    <Route key={`reset`} exact path="/reset-password/:token" component={ ResetPassword }/>,
    <Route key={`verify`} exact path="/verify/:token" component={ VerifyContainer }/>,
    <Route key={`resend-verification`} exact path="/resend-verification" component={ ResendVerification }/>,
    <Route key={ `examination-schedules` } path={ `/examination-schedules` } component={Schedule}/>,
    <Route key={'/verify-qr-code'} path="/verify-qr-code" component={ VerifyQRCode } />,

    <PrivateRoute key={`main`} isAuthenticated={ isAuthenticated } path="/main" component={ MainContainer }/>,

    //<Route key={`not-found`} component={ () => <div>404 Page not found. <Link to="/">Go to Home</Link></div> } />
])