import * as c from './constant';
import { Map, List, fromJS } from 'immutable';

export const INIT_STATE = Map({
	list: List([]),
	pager: Map({
        total: 0,
        current: 0
    }),
})

export default (state = INIT_STATE, action) => {
    switch(action.type){
        case c.GOT_LIST:
            return state.set("list", fromJS(action.data))
            	// .set(`pager`, fromJS({
                //     total: action.pager.last_page,
                //     current: parseInt(action.pager.current_page, 10)-1
                // }))	;
        default: 
            return state;
    }
}