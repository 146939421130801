export const GOT_AGENCY_PROFILE = "AGENCY_PROFILE/got_agency_profile";

export const SUBMIT_EDIT_AGENCY_PROFILE =
  "AGENCY_PROFILE/submit_edit_doh_profile";

export const SET_FORM_DATA = "AGENCY_PROFILE/set_form_data";
export const CLEAR_FORM_DATA = "AGENCY_PROFILE/clear_form_data";

export const FORM_DATA = {
  city: "",
  provice: "",
  validity_from: "",
  validity_to: "",
  email: "",
  address: "",
  id: "",
  is_active: "",
  mobile_number: "",
  name: "",
  photo: "",
  profile_id: "",
  telephone_number: "",
  sirn_no: "",
  medical_director: "",
  zipcode: ""
};
