import * as c from './constant';
import { Map, List, fromJS } from 'immutable';


const INIT_STATE = Map({
    paymentHistory: List([]),
    pager: Map({
    total: 0,
    current: 0
  })
});

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case c.GOT_HISTORY:
            return state.set('paymentHistory', fromJS(action.data)).set(
                `pager`,
                fromJS({
                total: action.pager.last_page,
                current: parseInt(action.pager.current_page, 20) - 1
            })
        );
        default:
            return state;
    }
}