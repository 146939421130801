import { takeLatest, takeEvery, put, call, all } from "redux-saga/effects";
import {
  loading,
  alert,
  history,
  services,
  watchApiReponse
  // loadState,
  // _
} from "../../Utils";

function* getTransactionOption({ id }) {
  yield put(loading("TRANSACTION/get_transaction_option"));

  const response = yield call(services.get(`ste/application/transaction`), {});

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    let newid = id;
    const redirectLink = () => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].status === "ENABLE") {
          newid = data[i].id;
          break;
        }
      }
      return `/main/transaction/${newid}`;
    };

    history.push(redirectLink());

    yield all([
      put({
        type: "TRANSACTION/got_transaction_option",
        data
      }),
      put({
        type: "TRANSACTION/got_transaction_detail",
        data: data.find(item => +item.id === +newid)
      }),
      getClasses({ txnid: newid }),
      // getDocuments({ txnid: newid }),
      getTypes({ txnid: newid }),
      getVenues()
      // getRequirements()
    ]);
  });
}

function* proceedToTransaction({ args }) {
  if (!args.transaction_id) return alert.error("Transaction is required");
  if (!args.type) return alert.error("Type is required");

  yield put({
    type: "TRANSACTION/set_transaction_data",
    args
  });
  yield history.push("/main/appointment");
}

function* proceedToRequirements({ args }) {
  yield put(loading("TRANSACTION/proceed_to_requirements"));

  const response = yield call(services.post(`ste/payment`), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { remarks, txnid } = response.data;

    yield alert.success(remarks);

    yield history.push(`/main/requirements/${txnid}`);
  });
}

function* paymentInfo({ txnid }) {
  yield put(loading("TRANSACTION/GET_PAYMENT_INFO"));

  const response = yield call(services.get(`ste/payment/${txnid}`), {});

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: "TRANSACTION/GOT_PAYMENT_INFO",
      data
    });
  });
}

function* getClasses({ txnid }) {
  yield put({
    type: "TRANSACTION/GOT_CLASSES",
    data: []
  });
  yield put(loading("TRANSACTION/GET_LISTS_UNDER_TRANSACTION"));

  const response = yield call(
    services.get(`ste/application/transaction/${txnid}/class`),
    {}
  );

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    const getClassOption = (args = []) => {
      let newData = [];
      args.forEach(item => {
        if (item.status === "ENABLE") {
          newData.push({
            ...item,
            value: item.id,
            label: item.name
          });
        }
      });
      return newData;
    };

    if (!(data.length > 0)) {
      yield getDocuments({ txnid });
    }

    yield put({
      type: "TRANSACTION/GOT_CLASSES",
      data: getClassOption(data)
    });
  });
}

function* getDocuments({ txnid, args }) {
  const params = args || {};
  yield put({
    type: "TRANSACTION/GOT_DOCUMENTS",
    data: []
  });

  yield put(loading("TRANSACTION/GET_LISTS_UNDER_TRANSACTION"));

  const response = yield call(
    services.get(`ste/application/transaction/${txnid}/document`),
    params || {}
  );

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    const getTransTypeOption = args => {
      let detailsOptions = [];

      const newDocuments = args.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      newDocuments.forEach(item => {
        if (item.status === "ENABLE") {
          detailsOptions.push({
            ...item,
            value: item.id,
            label: item.name
          });
        }
      });
      return detailsOptions;
    };

    yield put({
      type: "TRANSACTION/GOT_DOCUMENTS",
      data: getTransTypeOption(data)
    });
  });
}

function* getTypes({ txnid }) {
  yield put({
    type: "TRANSACTION/GOT_TYPES",
    data: []
  });

  yield put(loading("TRANSACTION/GET_LISTS_UNDER_TRANSACTION"));

  const response = yield call(
    services.get(`ste/application/transaction/${txnid}/type`),
    {}
  );

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: "TRANSACTION/GOT_TYPES",
      data
    });
  });
}

function* getBranches({ transaction_id }) {
  yield put({
    type: "TRANSACTION/GOT_BRANCH",
    data: []
  });

  yield put(loading("TRANSACTION/LOADING_GET_BRANCH"));

  const response = yield call(services.get(`ste/branch`), {
    transaction_id
  });

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: "TRANSACTION/GOT_BRANCH",
      data
    });
  });
}

function* getDocCertification({ txnid, args, callback }) {
  const params = args || {};

  yield put(loading("TRANSACTION/GET_DOCUMENTS/CERTIFICATE"));

  const uri = `ste/application/transaction/${txnid}/document`;

  const response = yield call(services.get(uri), params || {});

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    const getTransTypeOption = args => {
      let detailsOptions = [];

      const newDocuments = args.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      newDocuments.forEach(item => {
        if (item.status === "ENABLE") {
          detailsOptions.push({
            ...item,
            value: item.id,
            label: item.name
          });
        }
      });
      return detailsOptions;
    };

    yield callback(getTransTypeOption(data));
  });
}

function* getVenues() {
  yield put(loading("TRANSACTION/GET_LISTS_UNDER_TRANSACTION"));

  const url = `/ste/application/transaction/exam/venues`;

  const response = yield call(services.get(url), {});
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: "TRANSACTION/GOT_VENUES",
      data
    });
  });
}

function* getRequirements({ args }) {
  yield put(loading("TRANSACTION/GET_REQUIREMENTS"));

  const uri = `ste/application/transaction/requirement`;

  const response = yield call(services.get(uri), args);

  yield put(loading("TRANSACTION/GET_REQUIREMENTS", false));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: "TRANSACTION/GOT_REQUIREMENTS",
      data
    });
  });
}

function* getQualification({ args }) {
  yield put(loading("TRANSACTION/GET_QUALIFICATION"));

  const uri = `ste/application/transaction/qualification`;

  const response = yield call(services.get(uri), args);

  yield put(loading("TRANSACTION/GET_QUALIFICATION", false));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: "TRANSACTION/GOT_QUALIFICATION",
      data
    });
  });
}

export default function* profileSaga() {
  yield all([
    takeLatest("TRANSACTION/get_transaction_option", getTransactionOption),
    takeEvery("TRANSACTION/proceed_to_appointment", proceedToTransaction),
    takeEvery("TRANSACTION/proceed_to_requirements", proceedToRequirements),
    takeEvery("TRANSACTION/GET_PAYMENT_INFO", paymentInfo),
    takeLatest("TRANSACTION/GET_CLASSES", getClasses),
    takeLatest("TRANSACTION/GET_DOCUMENTS", getDocuments),
    takeLatest("TRANSACTION/GET_TYPES", getTypes),
    takeLatest("TRANSACTION/GET_DOCUMENTS/CERTIFICATE", getDocCertification),
    takeLatest("TRANSACTION/GET_BRANCH", getBranches),
    takeLatest("TRANSACTION/GET_VENUES", getVenues),
    takeLatest("TRANSACTION/GET_REQUIREMENTS", getRequirements),
    takeLatest("TRANSACTION/GET_QUALIFICATION", getQualification)
  ]);
}
