export const GET_VENUES = `SET_ONLINE_SCHEDULE/GET_VENUES`;
export const GOT_VENUES = `SET_ONLINE_SCHEDULE/GOT_VENUES`;

export const SET_FORM_DATA = `SET_ONLINE_SCHEDULE/SET_FORM_DATA`;

export const GET_EXAMINATION_SCHEDULE_LIST = `SET_ONLINE_SCHEDULE/GET_EXAMINATION_SCHEDULE_LIST`;
export const GOT_EXAMINATION_SCHEDULE_LIST = `SET_ONLINE_SCHEDULE/GOT_EXAMINATION_SCHEDULE_LIST`;

export const GET_TRANSACTION = "SET_ONLINE_SCHEDULE/GET_TRANSACTION";
export const GOT_TRANSACTION = "SET_ONLINE_SCHEDULE/GOT_TRANSACTION";

export const SET_EXAMINATION_ONLINE_SCHEDULE = `SET_ONLINE_SCHEDULE/SET_EXAMINATION_ONLINE_SCHEDULE`;
