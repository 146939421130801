import * as c from "./constant";
import { Map, List, fromJS } from "immutable";

export const INIT_STATE = Map({
  certificates: List([]),
  form_data: Map(c.FORM_DATA),
  pager: Map({
    total: 0,
    current: 0
  })
});

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case c.GOT_CERTIFICATES:
      return state.set("certificates", fromJS(action.data)).set(
        `pager`,
        fromJS({
          total: action.pager.last_page,
          current: parseInt(action.pager.current_page, 10) - 1
        })
      );
    case c.PRINT:
      return state.set("print", fromJS(action.data));
    case c.SET_FORM_DATA:
      return state.update("form_data", form_data =>
        form_data.merge(fromJS(action.data))
      );
    case c.CLEAR_FORM_DATA:
      return state.set("form_data", INIT_STATE.get("form_data"));

    default:
      return state;
  }
};
