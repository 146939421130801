import * as c from "./constant";
import { Map, List, fromJS } from "immutable";

export const INIT_STATE = Map({
  list: List([]),
  pager: Map({
    total: 0,
    current: 0
  }),
  branches: List([]),
  transactionTypeList: List([]),
  transactionList: List([]),
  certificateAppointmentList: List([])
});

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case c.GOT_LIST:
      return state.set("list", fromJS(action.data));
    // .set(`pager`, fromJS({
    //     total: action.pager.last_page,
    //     current: parseInt(action.pager.current_page, 10)-1
    // }))	;
    case c.GOT_BRANCH_LIST:
      return state.set(`branches`, fromJS(action.data));
    case c.GOT_BRANCH_TRANSACTION_LIST:
      return state.set(`transactionTypeList`, fromJS(action.data));
    case c.GOT_TRANSACTION_LIST:
      return state.set(`transactionList`, fromJS(action.data));
    case c.GOT_CERTIFICATE_LIST:
      return state.set(`certificateAppointmentList`, fromJS(action.data));
    default:
      return state;
  }
};
