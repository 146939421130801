import { List, Map } from 'immutable';

export const GET_LIST = 'ASSESSMENT_DOCUMENTS/get_list';
export const GOT_LIST = 'ASSESSMENT_DOCUMENTS/got_list';

export const ADD_TRAINING = 'ASSESSMENT_DOCUMENTS_ADD_TRAINING_SCHEDULE_INSTRUCTOR';

export const DELETE_TRAINING = 'ASSESSMENT_DOCUMENTS/delete';

export const EDIT = 'ASSESSMENT_DOCUMENTS_EDIT_TRAINING_SCHEDULE_INSTRUCTOR';

export const GET_SEAFARER = 'ASSESSMENT_DOCUMENTS/get_seafarer'
export const GOT_SEAFARER = 'ASSESSMENT_DOCUMENTS/get_seafarer/success'

export const ATTACH_SEAFARER = 'ASSESSMENT_DOCUMENTS/attach/seafarer'

export const ATTACH_SEAFARER_SUCCESS = 'ASSESSMENT_DOCUMENTS/attach/seafarer/success'

export const UPLOAD_ATTACHMENT = 'ASSESSMENT_DOCUMENTS/upload_attachment'
// export const GET_VIEW = 'TRAINING/get_view';
// export const GOT_VIEW = 'TRAINING/got_view';

export const GET_INSTRUCTOR = 'GET_INSTRUCTOR';
export const GOT_INSTRUCTOR = 'GOT_INSTRUCTOR';

export const INIT_STATE = Map({
  list: List([]),
  pager: Map({
    total: 0,
    current: 0,
  }),
  instructors: List([])
});
