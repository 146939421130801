export const AUTHENTICATE = 'AUTH/authenticate';
export const CHECK_AUTH = 'AUTH/check_auth';
export const IDENTIFY = 'AUTH/identify';
export const LOGIN = 'AUTH/login';
export const SIGN_OUT = 'AUTH/sign_out';
export const FORGOT_PASSWORD = 'AUTH/forgot_password';
export const VERIFY_RESET_CODE = 'AUTH/verify_reset_code';
export const RESET_PASSWORD = 'AUTH/reset_password';
export const CHANGE_PASSWORD = 'AUTH/change_password';
export const REGISTER = 'AUTH/register';
export const CHECK_ACCCOUNT = 'AUTH/check_account';
export const CHECK_ACCCOUNT_SUCCESS = 'AUTH/check_account_success';
export const CHECK_PROFILE_TYPE = 'AUTH/check_profile_type';
export const GET_MY_PROFILE = 'AUTH/get_my_profile';
export const GOT_MY_PROFILE = 'AUTH/got_my_profile';
export const VERIFY_ACCOUNT = 'AUTH/VERIFY_ACCOUNT';
export const RESEND_VERIFICATION = 'AUTH/RESEND_VERIFICATION';
export const GOT_VERIFY_ACCOUNT = 'AUTH/GOT_VERIFY_ACCOUNT';

export const VERIFY_CERTIFICATE = 'AUTH/verify_certificate';
export const VERIFY_CERTIFICATE_SUCCESS = 'AUTH/verify_certificate_success';

export const VERIRFY_IDENTIFICATION_CARD = 'AUTH/verify_identification_card';
export const VERIRFY_IDENTIFICATION_CARD_SUCCESS = 'AUTH/verify_identification_card_success';

export const VERIFY_SIRB_NUMBER = 'AUTH/verify_sirb_number';
export const VERIFY_SIRB_NUMBER_SUCCESS = 'AUTH/verify_sirb_number_success';

export const CLEAR_FORM_DATA_CERTIFICATE_OR_ID = 'AUTH/clear_form_data_certifacate_or_id';

export const SET_FORM_DATA = 'AUTH/set_form_data';
export const CLEAR_FORM_DATA = 'AUTH/clear_form_data';

export const CLEAR_STORAGE = 'AUTH/CLEAR_STORAGE';

export const FORM_DATA = {
  sirb_no: '',
  email: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  gender: '',
  birth_date: '',
  password: '',
  password_confirmation: '',
  term_condition: false,
  captcha: '',
};
