import * as c from './constant';
import { Map, fromJS } from 'immutable';
//import { _ } from 'app/Utils';

const initState = Map({
	agency_profile: Map({}),
	form_data: Map({}),
})

export default (state = initState, action) => {
	switch(action.type){
		
		case c.GOT_AGENCY_PROFILE:
			
			return state
				.set('agency_profile', fromJS(action.data))
				.set('form_data', fromJS(action.data))

		case c.SET_FORM_DATA:
            return state.update('form_data',form_data => form_data.merge(fromJS(action.data)));

		default:
			return state;
	}
}
