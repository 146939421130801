import { List, Map } from 'immutable';

export const GET_LIST = 'TCR_TRAINING_SCHEDULE/get_list';
export const GOT_LIST = 'TCR_TRAINING_SCHEDULE/got_list';

export const GET = 'TCR_TRAINING_SCHEDULE/get';
export const GOT = 'TCR_TRAINING_SCHEDULE/got';

export const ADD_TRAINING = 'TCR_ADD_TRAINING_SCHEDULE';

export const UPDATE_SCHEDULE = 'TCR_EDIT_TRAINING_SCHEDULE';

export const GET_SEAFARER = 'TCR_TRAINING_SCHEDULE/get_seafarer'
export const GOT_SEAFARER = 'TCR_TRAINING_SCHEDULE/get_seafarer/success'

export const ATTACH_SEAFARER = 'TCR_TRAINING_SCHEDULE/attach/seafarer'

export const ATTACH_SEAFARER_SUCCESS = 'TCR_TRAINING_SCHEDULE/attach/seafarer/success'
// export const GET_VIEW = 'TRAINING/get_view';
// export const GOT_VIEW = 'TRAINING/got_view';

export const INIT_STATE = Map({
  list: List([]),
  selected: Map({}),
  pager: Map({
    total: 0,
    current: 0,
  }),
});
