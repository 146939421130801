import { takeEvery, put, call, all } from 'redux-saga/effects';
import { loading, watchApiReponse, services, alert, _ } from '../../Utils';
import * as c from './constant';

function* getSteDocument(params) {

	const args = !_.isNil(params) ? params.args : {}
	const callback = !_.isNil(params) ? params.callback : (() => {})

	yield put(loading('GET_STE_DOCUMENT'));

	const url = `ste/document?paginate=0`;

	const response = yield call(services.get(url), (args || {}) );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data;	

		yield put({
			type: c.GOT_STE_DOCUMENT,
			data
		})

		if(callback)
			callback()
	});

}

function* attachFile({ args, onSuccess }) {

	yield put(loading('ATTACH_FILE'));

	const url = `ste/document`

	const response = yield call(services.post(url), args );

	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data

		alert.success(message)

		yield getSteDocument();

		if(onSuccess){
			onSuccess();
		}
	})
}

function* editAttachFile({ args, id, onSuccess }) {

	yield put(loading('EDIT_ATTACH_FILE'));

	const url = `ste/document/${id}`

	const response = yield call(services.put(url), args );

	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data

		alert.success(message)

		yield getSteDocument();

		if(onSuccess){
			onSuccess();
		}
	})
}

function* getSelectedAttachFile({ id }) {

	yield put(loading('GET_SELECTED_ATTACH_FILE'));

	const url = `ste/document/${id}`

	const response = yield call(services.get(url) );

	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data

		yield all([
			put({
				type: c.GOT_SELECTED_ATTACH_FILE,
				data
			}),
			put({
				type: c.SET_FORM_DATA,
				data: _.pick(data, _.keys(c.FORM_DATA))
			})

		])
	})
}

function* removeDocument({ id, onSuccess }) {
	
	yield put(loading('REMOVE_DOCUMENT'));

	const url = `ste/document/${id}`

	const response = yield call(services.remove(url) );

	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data

		alert.success(message)

		yield getSteDocument();

		if(onSuccess){
			onSuccess();
		}
	})

}

function* sync() {
	
	yield put(loading('GET_STE_DOCUMENT'));

	const url = `ste/document/sync`

	const response = yield call(services.post(url) );

	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data

		alert.success(message)

		yield getSteDocument();
	})

}

export default function*() {
	yield all([ 
		takeEvery(c.GET_STE_DOCUMENT, getSteDocument),
		takeEvery(c.ATTACH_FILE, attachFile),
		takeEvery(c.EDIT_ATTACH_FILE, editAttachFile),
		takeEvery(c.GET_SELECTED_ATTACH_FILE, getSelectedAttachFile),
		takeEvery(c.REMOVE_DOCUMENT, removeDocument),
		takeEvery(c.SYNC, sync)
	])
}