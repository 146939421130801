import { takeEvery, put, call, all, select } from 'redux-saga/effects';
import { loading, watchApiReponse, services, alert, _, moment } from '../../Utils';
import * as c from './constant';

function* getSeafarersData({ sirn_no, onSuccess }) {

	yield put(loading('GET_SEAFARERS_DATA'));

	const { profile } = yield select()

	let url = `agn/seafarer/${ sirn_no }`;

	if(profile.getIn([`profile`, `profile_type`]) === 'Doh'){
		url = `doh/seafarer/${ sirn_no }`;
	}
	
	if(profile.getIn([`profile`, `profile_type`]) === 'Ched'){
		url = `chd/seafarer/${ sirn_no }`;
	}

	const response = yield call(services.get(url), {} );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data	

		yield put({
			type: c.GOT_SEAFARERS_DATA,
			data
		})

		if(onSuccess){
			onSuccess(data);
		}
	});
}

function* getMedicalPractitioner() {

	yield put(loading('GET_MEDICAL_PRACTITIONER'));

	const { profile } = yield select()

	let url = `doh/medical_practitioner?paginate=0`;

	const response = yield call(services.get(url), {} );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data	

		yield put({
			type: c.GOT_MEDICAL_PRACTITIONER,
			data
		})
	});
}

function* getSeafarersServiceRecord({ sirn_no }) {

	yield put(loading('GET_SEAFARERS_SERVICE_RECORD'));

	const url = `agn/seafarer/${ sirn_no }/service_record`;

	const response = yield call(services.get(url), {} );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data	

		yield put({
			type: c.GOT_SEAFARERS_SERVICE_RECORD,
			data
		})

	});
}

function* getMedicalRecord({ sirn_no }) {

	yield put(loading('GET_MEDICAL_RECORD'));

	const url = `doh/seafarer/${ sirn_no }/medical_record`;

	const response = yield call(services.get(url), {} );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data	

		yield put({
			type: c.GOT_MEDICAL_RECORD,
			data
		})

	});
}

function* getVesselTypes() {

	yield put(loading('GET_VESSEL_TYPES'));

	const url = `/vessel_type`;

	const response = yield call(services.get(url), {} );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data	

		yield put({
			type: c.GOT_VESSEL_TYPES,
			data
		})

	});
}

function* getRankTypes() {

	yield put(loading('GET_RANK_TYPES'));

	const url = `/rank`;

	const response = yield call(services.get(url), {} );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data	

		yield put({
			type: c.GOT_RANK_TYPES,
			data
		})

	});
}

function* addServiceRecord({ args, sirn_no, onSuccess }) {

	yield put(loading('ADD_SERVICE_RECORD'));

	const url = `agn/seafarer/${ sirn_no }/service_record`;

	const response = yield call(services.post(url), args );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data	

		alert.success(message)

		yield getSeafarersServiceRecord({sirn_no})

		if(onSuccess){
			onSuccess();
		}

	});
}

function* addSchoolRecord({ args, sirn_no, onSuccess }) {

	yield put(loading('ADD_SCHOOL_RECORD'));

	const url = `chd/seafarer/${ sirn_no }`;

	const response = yield call(services.put(url), args );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data	

		alert.success(message)

		if(onSuccess){
			yield call(onSuccess);
		}

	});
}

function* addMedicalRecord({ args, sirn_no, onSuccess }) {

	yield put(loading('ADD_MEDICAL_RECORD'));

	const url = `doh/seafarer/${ sirn_no }/medical_record`;

	const response = yield call(services.post(url), args );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data	

		alert.success(message)

		if(onSuccess){
			yield call(onSuccess);
		}

		yield put({
			type: c.GET_MEDICAL_RECORD,
			sirn_no
		})

	});
}

function* getSelectedServiceRecord({ sirn_no, id }) {

	yield put(loading('GET_SELECTED_SERVICE_RECORD'));

	const url = `agn/seafarer/${ sirn_no }/service_record/${ id }`;

	const response = yield call(services.get(url), {} );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { data } = response.data	

		const args = {
            ...data,
            embarkation_date: moment(data.embarkation_date),
            disembarkation_date: moment(data.disembarkation_date)
        }

		yield all([
			put({
				type: c.GOT_SELECTED_SERVICE_RECORD,
				data
			}),
			put({
				type: c.SET_FORM_DATA,
				data: _.pick(args, _.keys(c.FORM_DATA))
			}),
		])

	});
}

function* editServiceRecord({ args, sirn_no, id, onSuccess }) {

	yield put(loading('EDIT_SERVICE_RECORD'));

	const url = `agn/seafarer/${ sirn_no }/service_record/${id}`;

	const response = yield call(services.put(url), args );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data	

		alert.success(message)

		yield getSeafarersServiceRecord({sirn_no})

		if(onSuccess){
			onSuccess();
		}

	});
}

function* deleteServiceRecord({ sirn_no, id }) {

	yield put(loading('EDIT_SERVICE_RECORD'));

	const url = `agn/seafarer/${ sirn_no }/service_record/${id}`;

	const response = yield call(services.remove(url), {} );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message } = response.data	

		alert.success(message)

		yield getSeafarersServiceRecord({sirn_no})

	});
}

export default function*() {
	yield all([ 
		takeEvery(c.GET_SEAFARERS_DATA, getSeafarersData),
		takeEvery(c.GET_SEAFARERS_SERVICE_RECORD, getSeafarersServiceRecord),
		takeEvery(c.GET_VESSEL_TYPES, getVesselTypes),
		takeEvery(c.GET_RANK_TYPES, getRankTypes),
		takeEvery(c.ADD_SERVICE_RECORD, addServiceRecord),
		takeEvery(c.GET_SELECTED_SERVICE_RECORD, getSelectedServiceRecord),
		takeEvery(c.EDIT_SERVICE_RECORD, editServiceRecord),
		takeEvery(c.DELETE_SERVICE_RECORD, deleteServiceRecord),
		takeEvery(c.ADD_SCHOOL_RECORD, addSchoolRecord),
		takeEvery(c.ADD_MEDICAL_RECORD, addMedicalRecord),
		takeEvery(c.GET_MEDICAL_RECORD, getMedicalRecord),
		takeEvery(c.GET_MEDICAL_PRACTITIONER, getMedicalPractitioner)
	])
}