export const GOT_TRAINING_CENTER_PROFILE =
  "TRAINING_CENTER_PROFILE/got_traning_center_profile";

export const EDIT_TRAINING_CENTER_PROFILE =
  "TRAINING_CENTER_PROFILE/edit_training_center_profile";

export const SET_FORM_DATA = "TRAINING_CENTER_PROFILE/set_form_data";

export const FORM_DATA = {
  photo: "",
  contact_person: "",
  contact_person_position: "",
  telephone_number: "",
  mobile_number: "",

  training_site_address: "",
  practicum_site_address: "",
  main_contact_person: "",
  main_contact_mobile: "",
  alternate_contact_person: "",
  alternate_contact_mobile: "",
  training_site_cctv: "",
  practicum_site_cctv: "",
  dedicated_account: ""
};
