import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './app/Store';
import App from './app/App';
import { ConnectedRouter } from 'react-router-redux';
import history from './app/History';
import { unregister } from './registerServiceWorker';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-select/dist/react-select.css';
import './assets/fonts/material/material.css';
import './assets/css/bootstrap4/bootstrap.min.css';
import './assets/css/bootstrap4/bootstrap-grid.min.css';
import './assets/css/bootstrap4/bootstrap-reboot.min.css';
import './assets/css/react-virtualized_2.css';
// import "./assets/css/profile-theme.min.css";
import 'react-virtualized/styles.css';
import './assets/css/style.css';
import './assets/css/mobile.css';

const rootElement = document.getElementById('root');

const appRender = async () => {
  const store = await configureStore();
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
};
appRender();
unregister();
