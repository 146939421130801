export const SET_LOADING = 'SET_LOADING';
export const DONE_LOADING = 'DONE_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const LOADING = 'LOADING';

export const LOADING_INIT_STATE = {
	loadingTypes: []
}

export const MODAL = 'MODAL';
export const IS_ONLINE = 'IS_ONLINE';