import * as c from './constant';
import { Map } from 'immutable';
import { _ } from '../../Utils';

const initStateModal = Map({
	isOpen: false,
	content: null,
	title: 'Modal Title',
	size: 'modal-md'
})

const loading = (state = c.LOADING_INIT_STATE, action) => {
	switch(action.type){
		case c.SET_LOADING:
			let setLoading = state.loadingTypes.concat([action.key]);
			return _.assign({}, state, {
				loadingTypes: setLoading
			})
		case c.DONE_LOADING:
			let doneLoading = state.loadingTypes.filter((type) => !(action.key === type))
			return _.assign({}, state, {
				loadingTypes: doneLoading
			})
		case c.CLEAR_LOADING:
			return _.assign({}, state, {
				loadingTypes: []
			})
		default: 
			return state;
	}
}

const modal = (state = initStateModal, action) => {
	switch(action.type){
		case c.MODAL:
			return state.merge(Map(action.data))
		default: 
			return state
	}
}

const utility = (state = { isOnline: true }, action) => {
	switch(action.type) {
		case c.IS_ONLINE:
			return _.assign({}, state, {
				isOnline: action.isOnline
			})
		default:
			return state;
	}
}

export {
	loading,
	modal,
	utility
}