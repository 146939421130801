import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  loading,
  alert,
  history,
  services,
  watchApiReponse
} from "../../Utils";
import * as c from "../manning-agency-seafarers/constant";

/*const transFormProfile = (data) => {
  return _.assign({}, data, {
    name: `${data.first_name} ${data.middle_name} ${data.last_name}`,
    birth_date: moment(data.birth_date).format("MM/DD/YYYY")
  })
}*/

function* getRank() {
  yield put(loading("PROFILE/GET_RANK"));
  const response = yield call(services.get(`rank`), {});
  yield put(loading("PROFILE/GET_RANK", false));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: "PROFILE/GOT_RANK",
      data
    });
  });
}

function* getVesselTypes() {
  yield put(loading("PROFILE/GET_VESSEL_TYPE"));
  const response = yield call(services.get(`vessel_type`), {});
  yield put(loading("PROFILE/GET_VESSEL_TYPE", false));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: "PROFILE/GOT_VESSEL_TYPE",
      data
    });
  });
}

function* updateProfile({ args }) {
  yield put(loading("GET_ME"));
  const response = yield call(services.post(`ste/profile`), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;
    alert.success(message);

    yield put({
      type: "AUTH/get_my_profile"
    });

    yield history.push(`/main`);
  });
}

function* updatePhoto({ photo }) {
  yield put(loading("UPDATE_HOTO"));
  const response = yield call(services.post(`ste/profile/change_photo`), {
    photo
  });
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    alert.success(message);

    yield put({
      type: `PROFILE/updated_photo`,
      photo
    });
  });
}

function* changePassword({ args }) {
  yield put(loading("CHANGE_PASSWORD"));

  let type = "";
  if (localStorage.getItem(`profile_type`) === "TrainingCenter") {
    type = "tcr";
  }

  if (localStorage.getItem(`profile_type`) === "AssessmentCenter") {
    type = "asc";
  }

  if (localStorage.getItem(`profile_type`) === "SiteUser") {
    type = "ste";
  }

  if (localStorage.getItem(`profile_type`) === "Agency") {
    type = "agn";
  }

  if (localStorage.getItem(`profile_type`) === "Doh") {
    type = "doh";
  }

  const response = yield call(
    services.post(`${type}/profile/change_password`),
    args
  );
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    yield alert.success("Password successfully changed");
    history.push("/main");
  });
}

function* getPrograms() {
  yield put(loading("PROFILE/GET_PROGRAM"));
  const response = yield call(services.get(`ste/program`), {});
  yield put(loading("PROFILE/GET_PROGRAM", false));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: "PROFILE/GOT_PROGRAM",
      data
    });
  });
}

function* getSchool() {
  yield put(loading("PROFILE/GET_PROGRAM"));
  const response = yield call(services.get(`ste/school`), {});
  yield put(loading("PROFILE/GET_PROGRAM", false));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: "PROFILE/GOT_SCHOOL",
      data
    });
  });
}

function* generateSRN() {
  yield put(loading("GENERATE_SRN"));
  const response = yield call(services.post(`ste/profile/generate_srn`), {});
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    alert.success(message);
    yield put({
      type: "AUTH/get_my_profile"
    });
    yield put({
      type: `GENERATE/srn/success`
    });
  });
}

export default function* profileSaga() {
  yield all([
    takeEvery(`PROFILE/update_profile`, updateProfile),
    takeEvery(`PROFILE/update_photo`, updatePhoto),
    takeEvery(`PROFILE/change_password`, changePassword),
    takeEvery(`PROFILE/GET_PROGRAM`, getPrograms),
    takeEvery(`PROFILE/GET_SCHOOL`, getSchool),
    takeEvery(`PROFILE/GET_RANK`, getRank),
    takeEvery(`PROFILE/GET_VESSEL_TYPE`, getVesselTypes),
    takeEvery(`GENERATE/srn`, generateSRN)
  ]);
}
