export const GET_VENUES = `SCHEDULE/GET_VENUES`;
export const GOT_VENUES = `SCHEDULE/GOT_VENUES`;

export const SET_FORM_DATA = `SCHEDULE/SET_FORM_DATA`;

export const GET_EXAMINATION_SCHEDULE_LIST = `SCHEDULE/GET_EXAMINATION_SCHEDULE_LIST`;
export const GOT_EXAMINATION_SCHEDULE_LIST = `SCHEDULE/GOT_EXAMINATION_SCHEDULE_LIST`;

export const GET_TRANSACTION = "SCHEDULE/GET_TRANSACTION";
export const GOT_TRANSACTION = "SCHEDULE/GOT_TRANSACTION";

export const SET_EXAMINATION_RESIT_SCHEDULE = `SCHEDULE/SET_EXAMINATION_RESIT_SCHEDULE`;

export const SET_EXAMINATION_RESCHEDULE = `SCHEDULE/SET_EXAMINATION_RESCHEDULE`;
