const MODULE = "LEGAL_RECORDS";

export const GET_LEGAL_RECORDS = `${MODULE}/GET_LEGAL_RECORDS`;
export const GOT_LEGAL_RECORDS = `${MODULE}/GOT_LEGAL_RECORDS`;

export const GET_DETAILS = `${MODULE}/get_details`;
export const GOT_DETAILS = `${MODULE}/got_details`;

export const SET_LEGAL_TRANSACTION = `${MODULE}/SET_LEGAL_TRANSACTION`;

export const GET_TRANSACTION_OPTION = "TRANSACTION/GET_TRANSACTION_OPTION";
export const GOT_TRANSACTION_OPTION = "TRANSACTION/GOT_TRANSACTION_OPTION";

export const GET_TYPES = "TRANSACTION/GET_TYPES";
export const GOT_TYPES = "TRANSACTION/GOT_TYPES";

export const GET_LEGAL_CLASSES = "TRANSACTION/GET_LEGAL_CLASSES";
export const GOT_LEGAL_CLASSES = "TRANSACTION/GOT_LEGAL_CLASSES";

export const GET_LEGAL_DOCUMENTS = "TRANSACTION/GET_LEGAL_DOCUMENTS";
export const GOT_LEGAL_DOCUMENTS = "TRANSACTION/GOT_LEGAL_DOCUMENTS";
