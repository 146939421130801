import { takeEvery, put, call, all } from "redux-saga/effects";
import * as c from "./constant";

import {
  loading,
  alert,
  history,
  services,
  watchApiReponse
} from "../../Utils";

function* getVenues() {
  yield put(loading("REQUEST_APPLICANT"));

  const url = `/ste/payment/schedule/exam/venues`;

  const response = yield call(services.get(url), {});
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_VENUES,
      data
    });
  });
}

function* getExaminationScheduleList({ txnid, schedule_type }) {
  yield put(loading("GET_EXAMINATION_LIST"));

  const url = `/ste/payment/schedule/exam`;

  const response = yield call(services.get(url), {
    txnid,
    schedule_type
  });

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_EXAMINATION_SCHEDULE_LIST,
      data
    });
  });
  yield put(loading("GET_EXAMINATION_LIST", false));
}

function* getTransaction({ txnid, callback }) {
  yield put(loading("SCHEDULE/GET_TRANSACTION"));

  const response = yield call(services.get(`/ste/payment/${txnid}`), {});

  yield put(loading("SCHEDULE/GET_TRANSACTION", false));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;
    callback(data);
    yield put({
      type: "SCHEDULE/GOT_TRANSACTION",
      data
    });
  });
}

function* setExaminationResitSchedule({ exam_schedule_id, txnid }) {
  yield put(loading("SCHEDULE/SET_EXAMINATION_RESIT_SCHEDULE"));

  const url = `/ste/payment/schedule/exam/resit`;

  const response = yield call(services.post(url), {
    exam_schedule_id,
    txnid
  });

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;
    alert.success(message);

    yield put({
      type: "MODAL",
      data: {
        isOpen: false
      }
    });
  });

  yield put(loading("SCHEDULE/SET_EXAMINATION_RESIT_SCHEDULE", false));

  history.push(`/main/application`);
}

function* setExaminationReschedule({ new_schedule_id, txnid }) {
  yield put(loading("SCHEDULE/SET_EXAMINATION_RESCHEDULE"));

  const url = `/ste/payment/schedule/exam/reschedule`;

  const response = yield call(services.post(url), {
    new_schedule_id,
    txnid
  });

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;
    alert.success(message);

    yield put({
      type: "MODAL",
      data: {
        isOpen: false
      }
    });
  });

  yield put(loading("SCHEDULE/SET_EXAMINATION_RESCHEDULE", false));

  history.push(`/main/application`);
}

export default function* profileSaga() {
  yield all([
    takeEvery(`SCHEDULE/GET_VENUES`, getVenues),
    takeEvery(
      `SCHEDULE/GET_EXAMINATION_SCHEDULE_LIST`,
      getExaminationScheduleList
    ),
    takeEvery(`SCHEDULE/GET_TRANSACTION`, getTransaction),
    takeEvery(
      `SCHEDULE/SET_EXAMINATION_RESIT_SCHEDULE`,
      setExaminationResitSchedule
    ),
    takeEvery(`SCHEDULE/SET_EXAMINATION_RESCHEDULE`, setExaminationReschedule)
  ]);
}
