import * as c from './constant';
import { fromJS/*, List, Map */} from 'immutable';
import { INIT_STATE } from './constant';

// const initState = Map({
//     list: List([])
// })

export default (state = INIT_STATE, action) => {
    switch(action.type){
        case c.GOT_LIST:
        
        	return state.set(`list`, fromJS(action.data))
        		// .set(`pager`, fromJS({
                //     total: action.pager.last_page,
                //     current: parseInt(action.pager.current_page, 10)-1
                // }))
        case c.GOT_LIST_THEORETICAL:
            return state.set(`list`, fromJS(action.data))

        // case c.SET_FORM_DATA:
        // 	return state.update(`formData`, formData => formData.merge(action.data))
        default: 
            return state;
    }
}