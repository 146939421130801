import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
    loading,
    alert,
    history,
    services,
    watchApiReponse,
    // loadState,
    // _
} from '../../Utils';
import * as c from './constant';

function* getServiceRecord({ args }) {
    yield put(loading('GET_SERVICE_RECORDS'));

    const response = yield call(services.get(`ste/service_record`), args)
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const { data, meta } = response.data;

        yield put({
            type: c.GOT_SERVICE_RECORDS,
            data,
            pagination: meta
        })
    })

}

function* create({ args }) {
    yield put(loading('GET_SERVICE_RECORDS'));

    const response = yield call(services.post(`ste/service_record`), args)
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const { message } = response.data;
        alert.success(message)

        yield getServiceRecord();
        history.push('/main/service-records')

    })

}


function* update({ args, id }) {
    yield put(loading('GET_SERVICE_RECORDS'));

    const response = yield call(services.put(`ste/service_record/${id}`), args)
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const { message } = response.data;
        alert.success(message)
        yield getServiceRecord();
        history.push('/main/service-records')
    })
}

function* details({ id }) {
    yield put(loading('GET_SERVICE_DETAILS'));

    const response = yield call(services.get(`ste/service_record/${id}`))
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const { data } = response.data;

        yield put({
            type: c.FORM_DATA,
            data
        })
    })
}

function* remove({ id }) {
    yield put(loading('REMOVE_RECORD'));

    const response = yield call(services.remove(`ste/service_record/${id}`))
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const { message } = response.data;
        alert.success(message)
        yield getServiceRecord();
        history.push('/main/service-records')

    })
}


export default function* profileSaga() {
    yield all([
        takeEvery(c.GET_SERVICE_RECORDS, getServiceRecord),
        takeEvery(c.CREATE, create),
        takeEvery(c.GET_DETAILS, details),
        takeEvery(c.UPDATE, update),
        takeEvery(c.REMOVE, remove),
    ])
}