export const GET_STE_DOCUMENT = 'DOCUMENT/get_ste_ducument';
export const GOT_STE_DOCUMENT = 'DOCUMENT/got_ste_ducument';

export const ATTACH_FILE = 'DOCUMENT/attach_file';
export const EDIT_ATTACH_FILE = 'DOCUMENT/edit_attach_file';

export const GET_SELECTED_ATTACH_FILE = 'DOCUMENT/get_selected_attach_file';
export const GOT_SELECTED_ATTACH_FILE = 'DOCUMENT/got_selected_attach_file';

export const REMOVE_DOCUMENT = 'DOCUMENT/remove_document';

export const SET_FORM_DATA = 'DOCUMENT/set_form_data';
export const CLEAR_FORM_DATA = 'DOCUMENT/clear_form_data';

export const SYNC = 'DOCUMENT/sync';
export const SYNC_SUCCESS = 'DOCUMENT/sync/success';

export const FORM_DATA = {
	label: '',
	file_path: '',
	description: '',
}