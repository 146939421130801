import { takeEvery, put, call, all } from 'redux-saga/effects';
import { loading, watchApiReponse, services } from '../../Utils';
import * as c from './constant';

function* getList({ args }){
	yield put(loading(c.GET_LIST));

	const url = `ste/medical_record?paginate=0`;

	const response = yield call(services.get(url), args);

	yield call(watchApiReponse, response, function*() {
    const {
      data,
    } = response.data;

		yield put({
			type: c.GOT_LIST,
			data,
		})
	});

	yield put(loading(c.GET_LIST, false));
}

export default function* Saga() {
	yield all([
		takeEvery(c.GET_LIST, getList)
	])
}