import {
  takeEvery,
  put,
  call,
  select,
  all,
  takeLatest
} from "redux-saga/effects";
import {
  loading,
  alert,
  history,
  services,
  watchApiReponse
  // moment,
  // loadState,
  // _
} from "../../Utils";

function* getRequirement({ txnid, id }) {
  yield put(loading("REQUIREMENTS/GET_REQUIREMENTS"));

  const uri = `/ste/payment/${txnid}/requirement`;

  const response = yield call(services.get(uri));

  yield put(loading("REQUIREMENTS/GET_REQUIREMENTS", false));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    let attachment = {};

    if (id) attachment = data.filter(item => +item.id === +id)[0] || {};

    if (!id && data.length > 0) {
      attachment = data[0];
      history.push(`/main/requirements/${txnid}/${data[0].id}`);
    }

    yield all([
      put({ type: "REQUIREMENTS/GOT_REQUIREMENTS", data }),
      put({ type: "REQUIREMENTS/GOT_ATTACHMENT", data: attachment })
    ]);
  });
}

function* getAttachment({ id }) {
  const { requirement } = yield select();
  const requirements = requirement.get("requirements");

  const response = requirements.toJS().filter(item => item.id === id)[0];

  yield put({
    type: "REQUIREMENTS/GOT_ATTACHMENT",
    data: response
  });
}

function* removeAttachment({ args }) {
  yield put(loading("REQUIREMENTS/REMOVE_ATTACHMENT"));

  const uri = `/ste/payment/${args.txnid}/requirement/${args.requirementId}/document/${args.documentId}`;

  const response = yield call(services.remove(uri));

  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    yield alert.success(message);

    yield getRequirement({ txnid: args.txnid, id: args.id });
  });
}

function* uploadAttachment({ args }) {
  yield put(loading("REQUIREMENTS/UPLOAD_ATTACHMENT"));

  const uri = `/ste/payment/${args.txnid}/requirement/${args.id}`;

  const response = yield call(services.put(uri), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    yield alert.success(message);

    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
        content: null,
        title: "",
        size: "modal-md"
      }
    });

    yield getRequirement({ txnid: args.txnid, id: args.id });
  });
}

function* getTransaction({ txnid, onSuccess }) {
  yield put(loading("REQUIREMENTS/GET_TRANSACTION"));

  const response = yield call(services.get(`/ste/payment/${txnid}`), {});

  yield put(loading("REQUIREMENTS/GET_TRANSACTION", false));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: "REQUIREMENTS/GOT_TRANSACTION",
      data
    });

    yield put({
      type: "TRANSACTION/set_transaction_data",
      args: data
    });

    if (onSuccess) {
      yield call(onSuccess, data);
    }

    if (data.transaction_name === "CERTIFICATION")
      yield getMoreDetails({ txnid });
  });
}

function* proceedToAppointment({ txnid }) {
  const { requirement /*transaction*/ } = yield select();
  const requirements = requirement.get("requirements");

  const data = requirements
    .toJS()
    .filter(
      item =>
        (item.file_url === null || item.status === "DRAFT") &&
        item.is_requied === 0
    );

  if (data.length > 0) {
    return alert.error("Please upload a file to all requirements.");
  }

  yield put(loading("REQUIREMENTS/PROCEED_TO_APPOINTMENT"));

  const response = yield call(services.put(`/ste/payment/${txnid}`), {});

  yield put(loading("REQUIREMENTS/PROCEED_TO_APPOINTMENT", false));

  yield call(watchApiReponse, response, function* () {
    const { data, message } = response.data;

    alert.success(
      message || "You have successfully generated a reference number."
    );

    yield put({
      type: "TRANSACTION/set_transaction_data",
      args: data
    });

    if (data.status === "FOR PAYMENT INITIATION") {
      return yield all([getRequirement({ txnid }), getTransaction({ txnid })]);
    }

    // history.push(`/main/appointment/${txnid}`);

    /*if([`CERTIFICATION`,`EXAMINATION`].indexOf(transaction.getIn([`transactionData`, `transaction_name`])) > -1){
			return history.push(`/main/application`)
    }*/

    return history.push("/main/application");
  });
}

function* uploadExistingAttachment({ args }) {
  yield put(loading("REQUIREMENTS/UPLOAD_EXISTING_ATTACHMENT"));

  const uri = `/ste/payment/${args.txnid}/requirement/${args.id}/attach`;

  const response = yield call(services.put(uri), args);

  yield put(loading("REQUIREMENTS/UPLOAD_EXISTING_ATTACHMENT", false));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    alert.success(
      message || "You have successfully generated a reference number."
    );

    yield getRequirement({
      txnid: args.txnid,
      id: args.id
    });

    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
        content: null,
        title: "",
        size: "modal-md"
      }
    });
  });
}

function* generateAttachment({ args }) {
  yield put(loading("REQUIREMENTS/GENERATE"));

  const uri = `/ste/payment/${args.txnid}/requirement/${args.id}/generate`;

  const response = yield call(services.put(uri));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield getRequirement({ txnid: args.txnid, id: args.id });
  });
  yield put(loading("REQUIREMENTS/GENERATE", false));
}

function* getMoreDetails({ txnid }) {
  yield put(loading("REQUIREMENTS/GENERATE"));

  const uri = `/ste/payment/${txnid}/transaction`;

  const response = yield call(services.get(uri));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: "REQUIREMENTS/GOT_MORE_DETAILS",
      data
    });
  });
  yield put(loading("REQUIREMENTS/GENERATE", false));
}

function* paymentInitiation({ txnid, onSuccess }) {
  yield put(loading("REQUIREMENTS/PAYMENT_INITIATION"));

  const uri = `/ste/payment/${txnid}/initiate`;

  const response = yield call(services.post(uri));

  yield call(watchApiReponse, response, function* () {
    const { message, url } = response.data;
    alert.success(message);
    history.push("/main/application");
    yield call(onSuccess, url);

    //yield window.open(url, '_blank');
  });

  yield put(loading("REQUIREMENTS/PAYMENT_INITIATION", false));
}

function* uploadReceipt({ txnid, payment_receipt }) {
  yield put(loading("REQUIREMENTS/UPLOAD_RECEIPT"));

  const response = yield call(
    services.post(`/ste/payment/${txnid}/payment-receipt`),
    {
      payment_receipt
    }
  );
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    alert.success(message);

    yield put({
      type: `REQUIREMENTS/UPLOADED_RECEIPT`,
      payment_receipt
    });
  });
}

function* generatePermit({ args }) {
  yield put(loading("REQUIREMENTS/GENERATE_PERMIT"));

  const url = `/ste/payment/schedule/exam/generate_permit`;

  const response = yield call(services.post(url), args);

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    history.push("/main/application");
  });

  yield put(loading("REQUIREMENTS/GENERATE_PERMIT", false));

  yield put({
    type: "PAYMENT/get_history"
  });
}

export default function* () {
  yield all([
    takeEvery(`REQUIREMENTS/GET_REQUIREMENTS`, getRequirement),
    takeEvery(`REQUIREMENTS/GET_ATTACHMENT`, getAttachment),
    takeEvery(`REQUIREMENTS/REMOVE_ATTACHMENT`, removeAttachment),
    takeEvery(`REQUIREMENTS/UPLOAD_ATTACHMENT`, uploadAttachment),
    takeEvery(`REQUIREMENTS/GET_TRANSACTION`, getTransaction),
    takeEvery(`REQUIREMENTS/PROCEED_TO_APPOINTMENT`, proceedToAppointment),
    takeEvery(
      "REQUIREMENTS/UPLOAD_EXISTING_ATTACHMENT",
      uploadExistingAttachment
    ),
    takeEvery("REQUIREMENTS/GENERATE", generateAttachment),
    takeEvery("REQUIREMENTS/GET_MORE_DETAILS", getMoreDetails),
    takeLatest("REQUIREMENTS/PAYMENT_INITIATION", paymentInitiation),
    takeEvery(`REQUIREMENTS/UPLOAD_RECEIPT`, uploadReceipt),
    takeEvery("REQUIREMENTS/GENERATE_PERMIT", generatePermit)
  ]);
}
