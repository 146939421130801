import * as c from './constant';
import { Map, List, fromJS } from 'immutable';


const CLEAR_STATE = Map({
    area_of_trading: '',
    disembarkation_date: '',
    embarkation_date: '',
    gross_tonnage: '',
    imo_no: '',
    kw: '',
    manning_agency: '',
    position: '',
    profile_id: '',
    vessel_name: '',
    vessel_type: '',
    vessel_type_id: ''
})
const INIT_STATE = Map({
    service_records: List([]),
    form_data: Map({
        area_of_trading: '',
        disembarkation_date: '',
        embarkation_date: '',
        gross_tonnage: '',
        imo_no: '',
        kw: '',
        manning_agency: '',
        position: '',
        profile_id: '',
        vessel_name: '',
        vessel_type: '',
        vessel_type_id: ''
    }),
    pagination: Map({}),
    details: Map({
        area_of_trading: '',
        disembarkation_date: '',
        embarkation_date: '',
        gross_tonnage: '',
        imo_no: '',
        kw: '',
        manning_agency: '',
        position: '',
        profile_id: '',
        vessel_name: '',
        vessel_type: '',
        vessel_type_id: ''
    }),
})

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case c.GOT_SERVICE_RECORDS:
            return state.set(`service_records`, fromJS(action.data))
                .set("pagination", fromJS(action.pagination));
        case c.FORM_DATA:
            return state.update(`form_data`, form_data => form_data.merge(fromJS(action.data)));
        case c.CLEAR_FORM_DATA:
            return state.set(`form_data`, CLEAR_STATE);
        case c.GOT_DETAILS:
            return state.set(`details`, fromJS(action.data));
        default:
            return state;
    }
}