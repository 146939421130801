import { takeLatest, put, call, all } from 'redux-saga/effects';
import { loading, services, watchApiReponse, alert } from '../../Utils';
import _ from 'lodash';
import * as c from './constant';

function* getList({ args }) {
  yield put(loading(c.GET_LIST));

  const url = 'ste/assessments?paginate=0';

  const response = yield call(services.get(url), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT_LIST,
      data
    });
  });
}

function* getListTheoretical({ args }) {
  yield put(loading(c.GET_LIST_THEORETICAL));

  const url = 'ste/exam/document';

  const response = yield call(services.get(url), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT_LIST_THEORETICAL,
      data
    });
  });
}

function* addTraining({ args }) {
  yield put(loading(c.GET_LIST));

  const url = 'ste/training';

  const response = yield call(services.post(url), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const message = _.get(response, 'data.message', 'Training created successfully!');

    alert.success(message);

    yield all([
      put({
        type: 'MODAL',
        data: {
          isOpen: false,
          content: null,
          title: '',
          modalSize: 'modal-md',
        },
      }),
      getList({ args: {} }),
    ]);
  });
}

export default function* Saga() {
  yield all([
    takeLatest(c.GET_LIST, getList),
    takeLatest(c.ADD_TRAINING, addTraining),
    takeLatest(c.GET_LIST_THEORETICAL, getListTheoretical)
  ]);
}
