import * as c from './constant';
import { fromJS/*, List, Map */} from 'immutable';
import { INIT_STATE } from './constant';

export default (state = INIT_STATE, action) => {
    switch(action.type){
        case c.GOT_LIST:

                
            return state.set(`list`, fromJS(action.data))
        default: 
            return state;
    }
}