import { takeEvery, put, call, all } from "redux-saga/effects";
import icon_multipay from "../../../assets/img/payment/icon-multipay.png";
import icon_7eleven from "../../../assets/img/payment/icon-7eleven.png";
import icon_bayadcenter from "../../../assets/img/payment/icon-bayadcenter.png";
import icon_ecpay from "../../../assets/img/payment/icon-ecpay.png";
import icon_bancnet from "../../../assets/img/payment/icon-bancnet.png";
import icon_banks from "../../../assets/img/payment/icon-banks.png";
import icon_online_banking from "../../../assets/img/payment/icon-online-banking.png";
import icon_debit_credit_card from "../../../assets/img/payment/icon-debit-credit-card.png";
import icon_globe from "../../../assets/img/payment/icon-globe.png";
import icon_smart from "../../../assets/img/payment/icon-smart.png";

import {
  loading,
  alert,
  history,
  services,
  watchApiReponse
} from "../../Utils";

function* getBranch({ txnid }) {
  yield put(loading("GET_BRANCH"));
  const response = yield call(services.get(`ste/payment/${txnid}/branch`));
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;
    yield put({
      type: `APPOINTMENT/got_branch`,
      data
    });
  });
}

function* getSchedule({ args }) {
  yield put({
    type: `APPOINTMENT/got_schedule`,
    data: []
  });

  yield put(loading("GET_SCHEDULE"));
  const response = yield call(services.get(`ste/appointment`), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;
    yield put({
      type: `APPOINTMENT/got_schedule`,
      data
    });
  });
}

function* setScheduleAlert({ schedule_date }) {
  yield alert.success(`You have selected ${schedule_date}`);
}

function* getPaymentChannel() {
  const channel = [
    {
      name: "Banks",
      id: "BANK",
      disabled: false,
      image: icon_banks
    },
    {
      name: "Online Banking",
      id: "ONLINEBANKING",
      disabled: false,
      image: icon_online_banking
    },
    {
      name: "Bayad Center",
      id: "BAYD",
      disabled: false,
      image: icon_bayadcenter
    },
    {
      name: "Multipay",
      id: "MPAY",
      disabled: false,
      image: icon_multipay
    },
    {
      name: "7-Eleven",
      id: "ECPAY",
      disabled: false,
      image: icon_7eleven
    },
    {
      name: "ECPay",
      id: "ECPAY",
      disabled: false,
      image: icon_ecpay
    },
    {
      name: "BancNet",
      id: "BANCNET",
      disabled: false,
      image: icon_bancnet
    },
    {
      name: "Debit/Credit Card",
      id: "DEBITCREDITCARD",
      disabled: false,
      image: icon_debit_credit_card
    },
    {
      name: "Globe GCash",
      id: "GLOBE",
      disabled: false,
      image: icon_globe
    },
    {
      name: "Smart Money",
      id: "SMART",
      disabled: false,
      image: icon_smart
    }
  ];

  yield put({
    type: `APPOINTMENT/got_payment_channel`,
    channel
  });
}

function* setPaymentChannel({ args, txnid }) {
  //if(!args.branch) return alert.error('Branch is required');
  if (!args.schedule_date) return alert.error("Schedule date is required");

  yield put({
    type: "APPOINTMENT/set_payment_channel_params",
    args
  });

  history.push({
    pathname: `/main/appointment/${txnid}/confirm`,
    state: { modal: true }
  });
}

function* payment({ args, callback }) {
  yield put(loading("PAYMENT"));
  const response = yield call(
    services.post(`ste/payment/${args.txnid}/appointment`),
    args
  );
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { url, message } = response.data;
    if (url) {
      yield put({
        type: `APPOINTMENT/get_payment_details`,
        data: response.data
      });

      return history.push({
        pathname: `/main/appointment/${args.txnid}/payment`,
        state: { modal: true }
      });
      /*setTimeout(
                    () => {
                        window.open(url, '_blank');
                        history.push('/main/application')    
                    }, 2000)*/

      // yield alert.success('Appointment successfully. You wil be redirect shorlty.')
    }

    history.push(`/main/application`);

    return alert.success(message);
  });
}

function* cancelPayment({ args }) {
  yield put(loading("PAYMENT"));
  const response = yield call(services.remove(`ste/payment/${args.txnid}`), {});
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { message } = response;
    alert.success(message || "Transaction has been cancelled");
    yield put({
      type: "PAYMENT/get_history"
    });
  });
}

export default function* profileSaga() {
  yield all([
    takeEvery(`APPOINTMENT/get_branch`, getBranch),
    takeEvery(`APPOINTMENT/get_schedule`, getSchedule),
    takeEvery(`APPOINTMENT/set_appointment_date`, setScheduleAlert),
    takeEvery(`APPOINTMENT/get_payment_channel`, getPaymentChannel),
    takeEvery(`APPOINTMENT/set_payment_channel`, setPaymentChannel),
    takeEvery(`APPOINTMENT/payment`, payment),
    takeEvery(`APPOINTMENT/cancel`, cancelPayment)
  ]);
}
