import { takeEvery, takeLatest, put, call, all } from "redux-saga/effects";
import {
  loading,
  alert,
  history,
  services,
  watchApiReponse
  // loadState,
  // _
} from "../../Utils";
import * as c from "./constant";

function* getLegalRecord({ args }) {
  yield put(loading("GET_SERVICE_RECORDS"));

  const response = yield call(services.get(`ste/legal_records`), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data, meta } = response.data;

    yield put({
      type: c.GOT_LEGAL_RECORDS,
      data,
      pagination: meta
    });
  });
}

function* setLegalTransaction({ args }) {
  yield put(loading("SET_LEGAL_DIVISION"));

  const response = yield call(services.post(`ste/payment`), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { remarks, txnid } = response.data;

    yield put({
      type: "MODAL",
      data: {
        isOpen: false
      }
    });

    yield alert.success(remarks);

    yield history.push(`/main/requirements/${txnid}`);
  });
}

function* getTransactionOption({ id }) {
  yield put(loading("TRANSACTION/get_transaction_option"));

  const response = yield call(services.get(`ste/application/transaction`), {});

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield all([
      put({
        type: "TRANSACTION/got_transaction_option",
        data
      })
    ]);
  });
}

function* getTypes({ txnid }) {
  yield put(loading("TRANSACTION/GET_LISTS_UNDER_TRANSACTION"));

  const response = yield call(
    services.get(`ste/application/transaction/${txnid}/type`),
    {}
  );

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: "TRANSACTION/GOT_TYPES",
      data
    });
  });
}

function* getClasses({ txnid }) {
  yield put({
    type: "TRANSACTION/GOT_LEGAL_CLASSES",
    data: []
  });
  yield put(loading("TRANSACTION/GET_LISTS_UNDER_TRANSACTION"));

  const response = yield call(
    services.get(`ste/application/transaction/${txnid}/class`),
    {}
  );

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    const getClassOption = (args = []) => {
      let newData = [];
      args.forEach(item => {
        if (item.status === "ENABLE") {
          newData.push({
            ...item,
            value: item.id,
            label: item.name
          });
        }
      });
      return newData;
    };

    yield put({
      type: "TRANSACTION/GOT_LEGAL_CLASSES",
      data: getClassOption(data)
    });
  });
}

function* getDocuments({ txnid, args }) {
  const params = args || {};
  yield put({
    type: "TRANSACTION/GOT_LEGAL_DOCUMENTS",
    data: []
  });

  yield put(loading("TRANSACTION/GET_LISTS_UNDER_TRANSACTION"));

  const response = yield call(
    services.get(`ste/application/transaction/${txnid}/document`),
    params || {}
  );

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    const getTransTypeOption = args => {
      let detailsOptions = [];

      const newDocuments = args.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      newDocuments.forEach(item => {
        if (item.status === "ENABLE") {
          detailsOptions.push({
            ...item,
            value: item.id,
            label: item.name
          });
        }
      });
      return detailsOptions;
    };

    yield put({
      type: "TRANSACTION/GOT_LEGAL_DOCUMENTS",
      data: getTransTypeOption(data)
    });
  });
}

export default function* profileSaga() {
  yield all([
    takeEvery(c.GET_LEGAL_RECORDS, getLegalRecord),
    takeEvery(c.SET_LEGAL_TRANSACTION, setLegalTransaction),
    takeEvery(c.GET_TRANSACTION_OPTION, getTransactionOption),
    takeEvery(c.GET_TYPES, getTypes),
    takeLatest(c.GET_LEGAL_CLASSES, getClasses),
    takeEvery(c.GET_LEGAL_DOCUMENTS, getDocuments)
  ]);
}
