import { takeEvery, put, call, all } from "redux-saga/effects";
import { loading, watchApiReponse, services, alert, _ } from "../../Utils";
import * as c from "./constant";

function* submitEditAgencyProfile({ args, onSuccess }) {
  yield put(loading("SUBMIT_EDIT_AGENCY_PROFILE"));

  const url = `doh/profile`;
  const response = yield call(services.put(url), args);

  yield put(loading(null));
  yield call(watchApiReponse, response, function*() {
    const { message, data } = response.data;
    alert.success(message);

    yield all([
      put({
        type: c.GOT_AGENCY_PROFILE,
        data
      }),
      put({
        type: c.SET_FORM_DATA,
        data: _.pick(data, _.keys(c.FORM_DATA))
      })
    ]);

    if (onSuccess) {
      onSuccess();
    }
  });
}

export default function*() {
  yield all([takeEvery(c.SUBMIT_EDIT_AGENCY_PROFILE, submitEditAgencyProfile)]);
}
