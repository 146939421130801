import { combineReducers } from "redux";

import auth from "./modules/auth/reducer";
import transaction from "./modules/transaction/reducer";
import appointment from "./modules/appointment/reducer";
import profile from "./modules/profile/reducer";
import { loading, modal, utility } from "./modules/common/reducer";
import paymentHistory from "./modules/payment-transaction/reducer";
import requirement from "./modules/requirement/reducer";
import serviceRecords from "./modules/service-records/reducer";
import training from "./modules/training/reducer";
import assessments from "./modules/assessments/reducer";
import documents from "./modules/document/reducer";
import certificate from "./modules/certificate/reducer";
import manning_agency_profile from "./modules/manning-agency-profile/reducer";
import training_center_profile from "./modules/training-center-profile/reducer";
import manning_agency_seafarers from "./modules/manning-agency-seafarers/reducer";
import legalRecords from "./modules/legal-records/reducer";

import assessment_center_profile from "./modules/assessment-center-profile/reducer";
import medical from "./modules/medical/reducer";
import examSchedule from "./modules/schedule/reducer";

import slotLocator from "./modules/slot-locator/reducer";
import setSchedule from "./modules/resit-reschedule/reducer";
import setOnlineSchedule from "./modules/online-schedule/reducer";
import vaccinationRecord from "./modules/vaccination-record/reducer";

//training center
import trainingCenterSchedule from "./modules/training-center-schedule/reducer";
import training_center_trainings from "./modules/training-center-trainings/reducer";
import trainingCenterScheduleInstructor from "./modules/training-center-training-instructor/reducer";
import trainingCenterScheduleSeafarer from "./modules/training-center-training-seafarer/reducer";
import training_center_documents from "./modules/training-center-documents/reducer";

//assessment center
import assessment_center_assessments from "./modules/assessment-center-assessments/reducer";
import assessment_center_schedule from "./modules/assessment-center-schedule/reducer";
import assessment_center_seafarer from "./modules/assessment-center-seafarer/reducer";
import assessment_center_documents from "./modules/assessment-center-documents/reducer";

export default combineReducers({
  auth,
  loading,
  modal,
  transaction,
  appointment,
  profile,
  paymentHistory,
  utility,
  requirement,
  serviceRecords,
  training,
  documents,
  certificate,
  manning_agency_profile,
  manning_agency_seafarers,
  training_center_profile,
  training_center_trainings,
  assessment_center_profile,
  medical,
  examSchedule,
  slotLocator,
  setSchedule,
  legalRecords,
  setOnlineSchedule,
  vaccinationRecord,

  trainingCenterSchedule,
  trainingCenterScheduleInstructor,
  trainingCenterScheduleSeafarer,
  training_center_documents,

  assessment_center_assessments,
  assessment_center_schedule,
  assessment_center_seafarer,
  assessment_center_documents,
  assessments
});
