import * as c from './constant';
import { Map, List, fromJS } from 'immutable';

const INIT_STATE = Map({
    branch: List([]),
    schedule: List([]),
    channel: List([]),
    paymentParams: Map({}),
    paymentChannelDetails: Map({})
})

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case c.GOT_BRANCH:
            return state.set(`branch`, fromJS(action.data));
        case c.GOT_SCHEDULE:
            return state.set(`schedule`, fromJS(action.data));
        case c.GOT_PAYMENT_CHANNEL:
            return state.set(`channel`, fromJS(action.channel))
        case c.SET_PAYMENT_CHANNEL_PARAMS:
            return state.set(`paymentParams`, fromJS(action.args))
        case c.GET_PAYMENT:
            return state.set(`paymentChannelDetails`, fromJS(action.data))
        default:
            return state;
    }
}