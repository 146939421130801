export const GOT_ASSESSMENT_CENTER_PROFILE =
  "ASSESSMENT_CENTER_PROFILE/got_assessment_center_profile";

export const SUBMIT_EDIT_ASSESSMENT_CETER_PROFILE =
  "ASSESSMENT_CENTER_PROFILE/submit_edit_assessment_center_profile";

export const SET_FORM_DATA = "ASSESSMENT_CENTER_PROFILE/set_form_data";

export const FORM_DATA = {
  contact_person: "",
  contact_person_position: "",
  date_approved: "",
  date_expiry: "",
  email: "",
  full_address: "",
  id: "",
  is_active: "",
  mobile_number: "",
  name: "",
  photo: "",
  profile_id: "",
  profile_type: "",
  telephone_number: "",

  training_site_address: "",
  practicum_site_address: "",
  main_contact_person: "",
  main_contact_mobile: "",
  alternate_contact_person: "",
  alternate_contact_mobile: "",
  training_site_cctv: "",
  practicum_site_cctv: "",
  dedicated_account: ""
};
