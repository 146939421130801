import { Map } from 'immutable';  
export const GOT_ME = 'PROFILE/got_me';
export const UPDATE_PROFILE = 'PROFILE/update_profile';

export const UPDATE_PHOTO = 'PROFILE/update_photo';
export const UPDATED_PHOTO = 'PROFILE/updated_photo';

export const CHANGE_PASSWORD = 'PROFILE/change_password';

export const GET_PROGRAM = 'PROFILE/GET_PROGRAM';
export const GOT_PROGRAM = 'PROFILE/GOT_PROGRAM';

export const GET_SCHOOL = 'PROFILE/GET_SCHOOL';
export const GOT_SCHOOL = 'PROFILE/GOT_SCHOOL';

export const GET_RANK = 'PROFILE/GET_RANK';
export const GOT_RANK = 'PROFILE/GOT_RANK';

export const GET_VESSEL_TYPE = 'PROFILE/GET_VESSEL_TYPE';
export const GOT_VESSEL_TYPE = 'PROFILE/GOT_VESSEL_TYPE';

export const SET_FORM_DATA = 'PROFILE/set_form_data';

export const EDUCATIONAL_ATTAINMENT = [
  {
    label: 'ELEMENTARY',
    value: 'ELEMENTARY'
  },
  {
    label: 'HIGHSCHOOL',
    value: 'HIGHSCHOOL'
  },
  {
    label: 'COLLEGE',
    value: 'COLLEGE'
  },
  {
    label: 'MASTERAL',
    value: 'MASTERAL'
  },
  {
    label: 'DOCTORAL',
    value: 'DOCTORAL'
  }
]

export const FORM_DATA = {
  highest_educational_attainment: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  birth_date: '',
  civil_status: '',
  gender: '',
  citizenship: '',
  photo: '',
  photo_2: '',
  photo_3: '',
  contact_number: '',
  program_id: '',
  school_id: '',
  region_code: '',
  suffix: '',
  municipality_code: '',
  province_code: '',
  current_address: '',
  barangay_code: '',
  year_graduated: '',
  place_of_birth: '',
  department: '',
  rank_id: '',
  current_rank_onboard_id: '',
  current_rank_onboard_name: '',
  current_rank_onboard_others: '',
  rank_coc_id: '',
  rank_coc_name: '',
  rank_coc_others: '',
  current_type_vessel_id: '',
  current_type_vessel_name: '',
  email: '',
  rank_name: '',
  rank_others: '',
  manning_agency: '',
  permanent_address: '',
  passport_no: '',
  accredited_liaison_officer: '',

  distinguishing_mark: '',
  hair: '',
  eyes: '',
  height: '',
  weight: '',

  region_name: '',
  province_name: '',
  municipality_name: '',
  barangay_name: '',
  zip_code: '',
  street: '',

  emergency_contact_name: '',
  emergency_contact_number: '',
  emergency_contact_address: '',
  school_name: '',
  school_program: '',

  nbi_clearance_id: '',
  nbi_clearance_date_issued: '',

  barangay: {},
  municipality: {},
  province: {},
  region: {},

  school_region_code: '',
  school_province_code: '',
  school_municipality_code: '',
  school_barangay_code: '',
  school_street: '',
  school_zip_code: '',
  so_number: '',
  program_name: '',
  selected_region: Map({}),
  selected_province: Map({}),
  selected_city: Map({}),
  selected_barangay: Map({})
};
