export const GET_BRANCH = 'APPOINTMENT/get_branch';
export const GOT_BRANCH = 'APPOINTMENT/got_branch';

export const GET_SCHEDULE = 'APPOINTMENT/get_schedule';
export const GOT_SCHEDULE = 'APPOINTMENT/got_schedule';

export const SET_APPOINTMENT_DATE = 'APPOINTMENT/set_appointment_date';

export const GET_PAYMENT_CHANNEL = 'APPOINTMENT/get_payment_channel';
export const GOT_PAYMENT_CHANNEL = 'APPOINTMENT/got_payment_channel';
export const SET_PAYMENT_CHANNEL_PARAMS = 'APPOINTMENT/set_payment_channel_params';
export const PAYMENT = 'APPOINTMENT/payment';
export const GET_PAYMENT = 'APPOINTMENT/get_payment_details';