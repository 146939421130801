import { Map, fromJS } from 'immutable';
import * as c from './constant';

const initState = Map({
  isAuthenticated: false,
  isValidate: false,
  form_data: Map(c.FORM_DATA),
  profile_type: '',
  profile: Map({}),
  isVerified: false,
  verified_ceritifacate: Map({}),
  verified_id_card: Map({}),
  verified_sirb_number: Map({}),
});

export default (state = initState, action) => {
  switch (action.type) {
    case c.SET_FORM_DATA:
      return state.update('form_data', form_data => form_data.merge(fromJS(action.data)));

    case c.AUTHENTICATE:
      return state.set(`isAuthenticated`, action.isSuccess);

    case c.CHECK_ACCCOUNT_SUCCESS:
      return state.set(`isValidate`, fromJS(action.isValidate));

    case c.CHECK_PROFILE_TYPE:
      return state.set('profile_type', fromJS(action.profile_type));

    case c.GOT_MY_PROFILE:
      return state.set('profile', fromJS(action.profile));

    case c.CLEAR_FORM_DATA:
      return state.set('form_data', initState.get('form_data'));

    case c.GOT_VERIFY_ACCOUNT:
      return state.set('isVerified', fromJS(action.bool));

    case c.VERIFY_CERTIFICATE_SUCCESS:
      return state.set('verified_ceritifacate', fromJS(action.data));

    case c.VERIRFY_IDENTIFICATION_CARD_SUCCESS:
      return state.set('verified_id_card', fromJS(action.data));

    case c.CLEAR_FORM_DATA_CERTIFICATE_OR_ID:
      return state
        .set('verified_ceritifacate', initState.get('verified_ceritifacate'))
        .set('verified_id_card', initState.get('verified_id_card'));

    case c.VERIFY_SIRB_NUMBER_SUCCESS:
      return state.set('verified_sirb_number', fromJS(action.data));

    default:
      return state;
  }
};
