import * as c from './constant';
import { fromJS/*, List, Map */} from 'immutable';
import { INIT_STATE } from './constant';

export default (state = INIT_STATE, action) => {
    switch(action.type){
        case c.GOT_LIST:

            if(action.pager){
                state.set(`pager`, fromJS({
                    total: action.pager.last_page,
                    current: parseInt(action.pager.current_page, 10)-1
                }))
            }

            return state.set(`list`, fromJS(action.data))
        case c.GOT_INSTRUCTOR:
            return state.set(`instructors`, fromJS(action.data))
        default: 
            return state;
    }
}