import * as c from "./constant";
import { Map, List, fromJS } from "immutable";

const INIT_STATE = Map({
  legal_records: List([]),
  pagination: Map({}),
  transaction: List([]),
  types: List([]),
  classes: List([]),
  documents: List([])
});

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case c.GOT_LEGAL_RECORDS:
      return state
        .set(`legal_records`, fromJS(action.data))
        .set("pagination", fromJS(action.pagination));
    case c.GOT_TRANSACTION_OPTION:
      return state.set(`transaction`, fromJS(action.data));
    case c.GOT_TYPES:
      return state.set(`types`, fromJS(action.data));
    case c.GOT_LEGAL_CLASSES:
      return state.set(`classes`, fromJS(action.data));
    case c.GOT_LEGAL_DOCUMENTS:
      return state.set(`documents`, fromJS(action.data));
    default:
      return state;
  }
};
