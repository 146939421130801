import { takeEvery, all } from "redux-saga/effects";
import authSaga from "./modules/auth/saga";
import profileSaga from "./modules/profile/saga";
import transactionSaga from "./modules/transaction/saga";
import appointmentSaga from "./modules/appointment/saga";
import paymentHistorySaga from "./modules/payment-transaction/saga";
import requirementSaga from "./modules/requirement/saga";
import serviceRecords from "./modules/service-records/saga";
import trainingSaga from "./modules/training/saga";
import documentSaga from "./modules/document/saga";
import certificateSaga from "./modules/certificate/saga";
import manning_agency_profile from "./modules/manning-agency-profile/saga";
import doh_profile from "./modules/doh-profile/saga";
import manning_agency_seafarers from "./modules/manning-agency-seafarers/saga";
import training_center_profile from "./modules/training-center-profile/saga";
import training_center_trainings from "./modules/training-center-trainings/saga";
import assessment_center_profile from "./modules/assessment-center-profile/saga";
import medical from "./modules/medical/saga";
import examSchedule from "./modules/schedule/saga";
import legalRecords from "./modules/legal-records/saga";

import slotLocator from "./modules/slot-locator/saga";
import setSchedule from "./modules/resit-reschedule/saga";
import setOnlineSchedule from "./modules/online-schedule/saga";
import vaccinationRecord from "./modules/vaccination-record/saga";

//training center
import trainingCenterSchedule from "./modules/training-center-schedule/saga";
import trainingCenterScheduleInstructor from "./modules/training-center-training-instructor/saga";
import trainingCenterScheduleSeafarer from "./modules/training-center-training-seafarer/saga";
import training_center_documents from "./modules/training-center-documents/saga";

//assessment center
import assessment_center_assessments from "./modules/assessment-center-assessments/saga";
import assessment_center_schedule from "./modules/assessment-center-schedule/saga";
import assessment_center_seafarer from "./modules/assessment-center-seafarer/saga";
import assessment_center_documents from "./modules/assessment-center-documents/saga";
import assessments from "./modules/assessments/saga";
export function* doLogout({ history }) {
  yield sessionStorage.clear();
  history.push("/");
}

export function* watchLogout() {
  yield takeEvery("LOGOUT", doLogout);
}

export default function* rootSaga() {
  yield all([
    watchLogout(),
    authSaga(),
    profileSaga(),
    transactionSaga(),
    appointmentSaga(),
    paymentHistorySaga(),
    requirementSaga(),
    serviceRecords(),
    certificateSaga(),
    trainingSaga(),
    documentSaga(),
    manning_agency_profile(),
    manning_agency_seafarers(),
    training_center_profile(),
    training_center_trainings(),
    assessment_center_profile(),
    medical(),
    examSchedule(),
    slotLocator(),
    setSchedule(),
    legalRecords(),
    setOnlineSchedule(),
    vaccinationRecord(),

    trainingCenterSchedule(),
    trainingCenterScheduleInstructor(),
    trainingCenterScheduleSeafarer(),
    training_center_documents(),

    assessment_center_assessments(),
    assessment_center_schedule(),
    assessment_center_seafarer(),
    assessment_center_documents(),
    assessments(),
    doh_profile()
  ]);
}
