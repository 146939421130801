import * as c from './constant';
import { Map, fromJS } from 'immutable';

const initState = Map({
	training_center_profile: Map({}),
	form_data: Map(c.FORM_DATA),
})

export default (state = initState, action) => {
	switch(action.type){

		case c.GOT_TRAINING_CENTER_PROFILE:
			return state
				.set('training_center_profile', fromJS(action.data))
				.set('form_data', fromJS(action.data))

		case c.SET_FORM_DATA:
            return state.update('form_data',form_data => form_data.merge(fromJS(action.data)));

        default:
        	return state;
	}
}