import React, { Component } from 'react';

const AsyncLoader = () => (
  <ul className="loading-box">
    <li />
    <li />
    <li />
  </ul>
);

export default importComponent => {
  class AsyncComponent extends Component {
    state = {
      component: null,
    };

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : AsyncLoader();
    }
  }

  return AsyncComponent;
};
