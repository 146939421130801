import React, { Component } from 'react';
import { withRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as c from './modules/auth/constant';
import Alert from 'react-s-alert';
import Routes from './Routes';
import Loader from './modules/common/container/Loader';
import AlertTemplate from './modules/common/component/AlertTemplate';
import Modal from './modules/common/container/Modal';
import 'react-datepicker/dist/react-datepicker.css';

class App extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch({
      type: c.CHECK_AUTH,
    });
  }

  onCloseModal = () => {
    const { dispatch } = this.props;
    dispatch({
      type: 'MODAL',
      data: {
        isOpen: false,
      },
    });
  };

  render() {
    const { isAuthenticated, isLoading, modal } = this.props;
    return (
      <div>
        <div key={`main`} className="main-wrapper">
          <Switch>
            <Routes isAuthenticated={isAuthenticated} />
          </Switch>
        </div>

        <Loader key={`loader`} isLoading={isLoading} />

        <Alert
          key={`alert`}
          stack={{ limit: 1 }}
          effect="slide"
          contentTemplate={AlertTemplate}
        />

        <div id="my-confirm-modal" />

        <Modal key={`modal-component`} data={modal} onClose={this.onCloseModal}>
          {modal.get('content')}
        </Modal>
      </div>
    );
  }
}

const mapPropsToState = (state, routeParams) => {
  const isAuthenticated = state.auth.get('isAuthenticated');

  const { modal } = state;
  const isLoading = state.loading.loadingTypes.length > 0;

  // console.log(utility);

  return {
    isAuthenticated,
    isLoading,
    modal,
  };
};

export default withRouter(connect(mapPropsToState)(App));
