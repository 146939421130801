import { delay } from 'redux-saga';
import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
  loading,
  alert,
  history,
  services,
  watchApiReponse,
  _,
  moment,
} from 'app/Utils';
import * as c from './constant';

const transFormProfile = data => {
  return _.assign({}, data, {
    name: `${data.first_name} ${data.middle_name} ${data.last_name}`,
    birth_date: moment(data.birth_date).format('MM/DD/YYYY'),
  });
};

function* checkAuthentication() {
  const token = localStorage.getItem('token');

  if (token) {
    return yield put({
      type: c.AUTHENTICATE,
      isSuccess: true,
    });
  }

  yield put({
    type: c.AUTHENTICATE,
    isSuccess: false,
  });
}

function* identify({ args }) {
  yield put(loading('IDENTIFY'));
  const response = yield call(services.post('/ste/auth/verify'), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { has_record, name, url } = response.data;
    if (has_record) {
      yield history.push({
        pathname: '/signin',
        state: {
          photo: url,
          name: name,
          email: args.email,
        },
      });
      return;
    }
    yield alert.warning(
      `No account has been associated with the email "${args.email}".`
    );
    yield history.push({
      pathname: '/signup',
      state: {
        email: args.email,
      },
    });
  });
}

function* login({ args, callback }) {
  yield put(loading('LOGIN'));
  const response = yield call(services.post('/auth'), args);
  yield put(loading(null));

  yield call(
    watchApiReponse,
    response,
    function* () {
      const {
        message, token,
        is_first_login,
        profile_type,
      } = response.data;

      alert.success(message || 'You have logged in...');

      localStorage.setItem('token', token);
      localStorage.setItem('profile_type', profile_type);

      yield all([
        put({
          type: 'PROFILE/got_me',
          profile: {},
        }),
        put({
          type: c.AUTHENTICATE,
          isSuccess: true,
        }),
        call(callback),
      ]);

      history.push(+is_first_login ? '/main/profile/edit' : '/main');
    },
    function* () {
      yield call(callback);
    }
  );
}

function* logout() {
  yield put(loading('LOGIN'));

  const response = yield call(services.put('/auth'));

  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    alert.warning('You have logged out');

    sessionStorage.clear();
    localStorage.clear();

    yield put({
      type: c.AUTHENTICATE,
      isSuccess: false,
      token: undefined,
    });

    history.push({ pathname: '/' });
  });
}

function* forgotPassword({ args }) {
  yield put(loading('FORGOT_PASSWORD'));

  const uri = '/pub/forgot';

  const response = yield call(services.post(uri), args);

  yield put(loading('FORGOT_PASSWORD', false));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    history.push('/');

    yield alert.success(message);
  });
}

function* verifyResetCode({ code, cb }) {
  yield put(loading('VERIFY_RESET_CODE'));

  yield delay(900); //FAKE API LOADING!
  console.log('Code Verified', code);

  yield put(loading(null));

  const isExpired = false;

  cb(isExpired);
}

function* changePassword({ args, cb }) {
  yield put(loading('CHANGE_PASSWORD'));

  yield delay(900); //FAKE API LOADING!

  yield put(loading(null));

  alert.warning(`Password has been changed.`);

  cb();

  yield delay(3000);
  history.push('/');
}

function* register({ args, isNew, callbackFailed }) {
  yield put(loading('REGISTER'));

  const url = isNew > 0 ? `/ste/register/new` : `/ste/register/sirb`;
  const response = yield call(services.post(url), args);

  yield put(loading(null));

  yield call(
    watchApiReponse,
    response,
    function* () {
      const { message } = response.data;

      yield alert.success(message);

      history.push('/signin');
    },
    function* (argument) {
      if (callbackFailed) {
        yield callbackFailed();
      }
    }
  );
}

function* checkAccount({ args }) {
  yield put(loading('CHECK_ACCCOUNT'));

  const response = yield call(services.post('ste/register/check'), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { data, message } = response.data;

    const args = {
      ...data,
      birth_date: moment(data.birth_date).format('MM/DD/YYYY'),
    };

    alert.success(message);

    yield all([
      put({
        type: c.CHECK_ACCCOUNT_SUCCESS,
        isValidate: true,
      }),
      put({
        type: c.SET_FORM_DATA,
        data: _.pick(args, _.keys(c.FORM_DATA)),
      }),
    ]);
  });
}

function* getMyProfile() {
  const profile_type = localStorage.getItem('profile_type');
  if (_.isNil(profile_type) || profile_type === '') return yield logout();

  yield put(loading('GET_MY_PROFILE'));

  let url = '';

  if (profile_type === 'Agency') {
    url = `agn/profile`;
  }

  if (profile_type === 'SiteUser') {
    url = `ste/profile`;
  }

  if (profile_type === 'TrainingCenter') {
    url = `tcr/profile`;
  }

  if (profile_type === 'AssessmentCenter') {
    url = `asc/profile`;
  }

  if (profile_type === 'Doh') {
    url = `doh/profile`;
  }

  if (profile_type === 'Ched') {
    url = `chd/profile`;
  }

  const response = yield call(services.get(url));
  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;

    let newData = {};

    if (profile_type === 'Agency') {
      newData = data;

      yield put({
        type: 'AGENCY_PROFILE/got_agency_profile',
        data: newData,
      });
    }

    if (profile_type === 'Doh') {
      newData = {
        ...data
      };
      yield put({
        type: 'AGENCY_PROFILE/got_agency_profile',
        data: newData,
      });
      yield put({
        type: 'PROFILE/got_me',
        profile: newData,
      });
    }

    if (profile_type === 'Ched') {
      newData = {
        ...data,
        name: `${data.first_name} ${data.last_name}`
      };
      yield put({
        type: 'AGENCY_PROFILE/got_agency_profile',
        data: newData,
      });
      yield put({
        type: 'PROFILE/got_me',
        profile: newData,
      });
    }

    if (profile_type === 'TrainingCenter') {
      newData = data;

      yield put({
        type: 'TRAINING_CENTER_PROFILE/got_traning_center_profile',
        data: newData,
      });
      yield put({
        type: 'PROFILE/got_me',
        profile: newData,
      });
    }

    if (profile_type === 'AssessmentCenter') {
      newData = data;
      yield put({
        type: 'ASSESSMENT_CENTER_PROFILE/got_assessment_center_profile',
        data: newData,
      });
      yield put({
        type: 'PROFILE/got_me',
        profile: newData,
      });
    }

    if (profile_type === 'SiteUser') {
      newData = transFormProfile(data);
      yield put({
        type: 'PROFILE/got_me',
        profile: newData,
      });
    }
  });
}

function* verifyAccount({ args }) {
  yield put(loading('VERIFY_ACCOUNT'));

  const uri = '/pub/verify';

  const response = yield call(services.post(uri), args);

  yield put(loading('VERIFY_ACCOUNT', false));

  yield call(
    watchApiReponse,
    response,
    function* () {
      const { message } = response.data;

      // history.push('/')

      yield put({
        type: c.GOT_VERIFY_ACCOUNT,
        bool: true,
      });

      yield alert.success(message);
    },
    function* () {
      yield put({
        type: c.GOT_VERIFY_ACCOUNT,
        bool: false,
      });
    }
  );
}

function* resendVerification({ args }) {
  yield put(loading('RESEND_VERIFICATION'));

  const uri = '/pub/resend_verification';

  const response = yield call(services.post(uri), args);

  yield put(loading('RESEND_VERIFICATION', false));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    history.push('/');

    yield alert.success(message);
  });
}

function* resetPassword({ args }) {
  yield put(loading('RESET_PASSWORD'));

  const url = '/pub/reset';

  const response = yield call(services.post(url), args);

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    history.push('/signin');

    yield alert.success(message);
  });

  yield put(loading('RESET_PASSWORD', false));
}

function* verifyCertificate({ args, onSuccess, onResetCapcha }) {
  yield put(loading('VERIFY_ACCOUNT'));

  const url = `/pub/archive/certificate/verify`;
  const response = yield call(services.get(url), args);

  yield call(watchApiReponse, response, function* () {
    const { status } = response;
    if (status < 400) {
      const { data } = response.data;

      yield put({
        type: c.VERIFY_CERTIFICATE_SUCCESS,
        data,
      });

      if (onSuccess) {
        onSuccess();
      }

      return;
    }
    
    if (onResetCapcha) {
      onResetCapcha();
    }
  }, 
  function *(){
    if (onResetCapcha) {
      onResetCapcha();
    }
  });

  yield put(loading('VERIFY_ACCOUNT', false));
}

function* verifyIdentificationCard({ args, onSuccess, onResetCapcha }) {
  yield put(loading('VERIRFY_IDENTIFICATION_CARD'));

  const url = `/pub/archive/id_card/verify`;
  const response = yield call(services.get(url), args);

  yield call(watchApiReponse, response, function* () {
    const { status } = response;
    if (status < 400) {
      const { data } = response.data;

      yield put({
        type: c.VERIRFY_IDENTIFICATION_CARD_SUCCESS,
        data,
      });

      if (onSuccess) {
        onSuccess();
      }

      return;
    }

    if (onResetCapcha) {
      onResetCapcha();
    }
  }, 
  function *(){
    if (onResetCapcha) {
      onResetCapcha();
    }
  });

  yield put(loading('VERIRFY_IDENTIFICATION_CARD', false));
}

function* verifySirbNumber({ args, onSuccess, onResetCapcha }) {
  yield put(loading('VERIFY_SIRB_NUMBER'));

  const url = `/pub/sirb/verify`;
  const response = yield call(services.get(url), args);

  yield call(watchApiReponse, response, function* () {
    const { status } = response;
    if (status < 400) {
      const { data } = response.data;

      yield put({
        type: c.VERIFY_SIRB_NUMBER_SUCCESS,
        data,
      });

      if (onSuccess) {
        onSuccess();
      }

      return;
    }

    if (onResetCapcha) {
      onResetCapcha();
    }
  }, 
  function *(){
    if (onResetCapcha) {
      onResetCapcha();
    }
  });

  yield put(loading('VERIFY_SIRB_NUMBER', false));
}

function* clearStorage(argument) {
  alert.warning('You have logged out');

  sessionStorage.clear();
  localStorage.clear();

  yield put({
    type: c.AUTHENTICATE,
    isSuccess: false,
    token: undefined,
  });

  history.push({ pathname: '/' });
}

export default function* authSaga() {
  yield all([
    takeEvery(c.CHECK_AUTH, checkAuthentication),
    takeEvery(c.IDENTIFY, identify),
    takeEvery(c.LOGIN, login),
    takeEvery(c.SIGN_OUT, logout),
    takeEvery(c.FORGOT_PASSWORD, forgotPassword),
    takeEvery(c.VERIFY_RESET_CODE, verifyResetCode),
    takeEvery(c.RESET_PASSWORD, resetPassword),
    takeEvery(c.CHANGE_PASSWORD, changePassword),
    takeEvery(c.REGISTER, register),
    takeEvery(c.CHECK_ACCCOUNT, checkAccount),
    takeEvery(c.GET_MY_PROFILE, getMyProfile),
    takeEvery(c.VERIFY_ACCOUNT, verifyAccount),
    takeEvery(c.RESEND_VERIFICATION, resendVerification),
    takeEvery(c.VERIFY_CERTIFICATE, verifyCertificate),
    takeEvery(c.VERIRFY_IDENTIFICATION_CARD, verifyIdentificationCard),
    takeEvery(c.VERIFY_SIRB_NUMBER, verifySirbNumber),
    takeEvery(c.CLEAR_STORAGE, clearStorage),
  ]);
}
