import React, { Component } from 'react';

class Loader extends Component {
    render() {
        if(!this.props.isLoading) {
            return null;
        }
        
        return (
            <div className="loader-wrapper">
                <div className="loader"></div>
                <div className="loader"></div>
            </div>
        );
    }
}

export default Loader
