import * as c from './constant';
import { Map, fromJS, List } from 'immutable';

const initState = Map({
	seafarers_data: Map({}),
	seafarers_service_record: List([]),
	form_data: Map(c.FORM_DATA),
	vessel_types: List([]),
	rank_types: List([]),
	medical_records: List([]),
	medical_practitioners: List([])
})

export default (state = initState, action) => {
	switch(action.type){

		case c.GOT_SEAFARERS_DATA:
			return state.set('seafarers_data', fromJS(action.data))
							.set(`form_data`, fromJS(action.data));
		
		case c.GOT_MEDICAL_PRACTITIONER:
			return state.set('medical_practitioners', fromJS(action.data));

		case c.GOT_SEAFARERS_SERVICE_RECORD:
			return state.set('seafarers_service_record', fromJS(action.data));

		case c.GOT_VESSEL_TYPES:
			return state.set('vessel_types', fromJS(action.data));

		case c.GOT_RANK_TYPES:
			return state.set('rank_types', fromJS(action.data));

		case c.CLEAR_FORM_DATA:
			return state.set('form_data', initState.get('form_data'))

		case c.CLEAR_SEAFARERS_DATA:
			return state.set('seafarers_data', initState.get('seafarers_data'));

		case c.SET_FORM_DATA:
            return state.update('form_data',form_data => form_data.merge(fromJS(action.data)));

		case c.GOT_MEDICAL_RECORD:
			return state.set(`medical_records`, fromJS(action.data))

		default:
			return state;
	}
}
