import { takeEvery, put, call, all } from "redux-saga/effects";
import { loading, watchApiReponse, services, alert } from "../../Utils";
import * as c from "./constant";

function* getCertificates({ args }) {
  yield put(loading(c.GET_CERTIFICATES));

  const url = `ste/certificate`;

  const response = yield call(services.get(url), args);

  yield call(watchApiReponse, response, function* () {
    const { data, meta } = response.data;

    yield put({
      type: c.GOT_CERTIFICATES,
      data,
      pager: meta
    });
  });

  yield put(loading(c.GET_CERTIFICATES, false));
}

function* getPrint({ transactionId }) {
  yield put(loading(c.GET_CERTIFICATES));

  const response = yield call(
    services.get(`/ste/certificate/${transactionId}`)
  );

  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: c.PRINT,
      data
    });
  });
}

function* attachFile({ file_url, transactionId }) {
  yield put(loading("ATTACH_FILE"));

  const url = `ste/certificate/${transactionId}/signed_certificate`;

  const response = yield call(services.post(url), file_url);

  yield put(loading(null));

  yield call(watchApiReponse, response, function* () {
    const { message } = response.data;

    alert.success(message);

    yield put({
      type: "MODAL",
      data: {
        isOpen: false
      }
    });

    yield getCertificates({ args: { status: "VALID" } });
  });
}

export default function* Saga() {
  yield all([
    takeEvery(c.GET_CERTIFICATES, getCertificates),
    takeEvery(c.GET_PRINT, getPrint),
    takeEvery(c.ATTACH_FILE, attachFile)
  ]);
}
