export const SET_FORM_DATA = "SEAFARERS/set_form_data";
export const CLEAR_FORM_DATA = "SEAFARERS/clear_form_data";
export const CLEAR_SEAFARERS_DATA = "SEAFARERS/clear_seafarers_data";

export const ADD_SERVICE_RECORD = "SEAFARERS/add_service_record";
export const EDIT_SERVICE_RECORD = "SEAFARERS/edit_service_record";
export const DELETE_SERVICE_RECORD = "SEAFARERS/delete_service_record";

export const GET_SELECTED_SERVICE_RECORD =
  "SEAFARERS/get_selected_service_record";
export const GOT_SELECTED_SERVICE_RECORD =
  "SEAFARERS/got_selected_service_record";

export const GET_VESSEL_TYPES = "SEAFARERS/get_vessel_types";
export const GOT_VESSEL_TYPES = "SEAFARERS/got_vessel_types";

export const GET_RANK_TYPES = "SEAFARERS/get_rank_types";
export const GOT_RANK_TYPES = "SEAFARERS/got_rank_types";

export const GET_SEAFARERS_DATA = "SEAFARERS/get_seafarers_data";
export const GOT_SEAFARERS_DATA = "SEAFARERS/got_seafarers_data";

export const GET_SEAFARERS_SERVICE_RECORD =
  "SEAFARERS/get_seafarers_service_record";
export const GOT_SEAFARERS_SERVICE_RECORD =
  "SEAFARERS/got_seafarers_service_record";

export const ADD_SCHOOL_RECORD = "SEAFARERS/add_SCHOOL_RECORD";
export const EDIT_SCHOOL_RECORD = "SEAFARERS/edit_SCHOOL_record";

export const ADD_MEDICAL_RECORD = "SEAFARERS/add_MEDICAL_RECORD";
export const EDIT_MEDICAL_RECORD = "SEAFARERS/edit_MEDICAL_record";

export const GET_MEDICAL_RECORD = "SEAFARER/get/medical/record";
export const GOT_MEDICAL_RECORD = "SEAFARER/got/medical/record";

export const GET_MEDICAL_PRACTITIONER = "SEAFARER/get/medical/practitioner";
export const GOT_MEDICAL_PRACTITIONER = "SEAFARER/got/medical/practitioner";

export const FORM_DATA = {
  sirn_no: "",
  vessel_name: "",
  vessel_type_id: "",
  position: "",
  imo_no: "",
  gross_tonnage: "",
  embarkation_date: "",
  disembarkation_date: "",
  area_of_trading: "",
  kw: "",
  school_name: "",
  no_of_months: "",
  no_of_days: "",
  flag_registry: "",
  ships_trade: "",
  embarkation_place: "",
  disembarkation_place: ""
};
