export const GET_CERTIFICATES = "CERTIFICATE/get_certificates";
export const GOT_CERTIFICATES = "CERTIFICATE/got_certificates";

export const GET_PRINT = "CERTIFICATE/get_print";
export const PRINT = "CERTIFICATE/PRINT";

export const SET_FORM_DATA = "CERTIFICATE/set_form_data";
export const CLEAR_FORM_DATA = "CERTIFICATE/clear_form_data";

export const ATTACH_FILE = "CERTIFICATE/attach_file";

export const FORM_DATA = {
  file_url: ""
};
