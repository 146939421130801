import * as c from "./constant";
import { Map, List, fromJS } from "immutable";

const initState = Map({
  requirements: List([]),
  attachment: Map({}),
  transaction: Map({}),
  viewMoreDetails: List([])
});

export default (state = initState, action) => {
  switch (action.type) {
    case c.GOT_REQUIREMENTS:
      return state.set(`requirements`, fromJS(action.data));
    case c.GOT_ATTACHMENT:
      return state.set(`attachment`, fromJS(action.data));
    case c.GOT_TRANSACTION:
      return state.set(`transaction`, fromJS(action.data));
    case c.GOT_MORE_DETAILS:
      return state.set("viewMoreDetails", fromJS(action.data));
    case c.CLEAR_STATE:
      return initState;

    case c.UPLOADED_RECEIPT:
      return state.setIn(
        [`transaction`, `payment_receipt`],
        action.payment_receipt
      );

    default:
      return state;
  }
};
