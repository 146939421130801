const MODULE = "SLOT_LOCATOR";

export const GET_LIST = `${MODULE}/get/list`;
export const GOT_LIST = `${MODULE}/got/list`;

export const GET_BRANCH_LIST = `${MODULE}/get/branch/list`;
export const GOT_BRANCH_LIST = `${MODULE}/got/branch/list`;

export const GET_BRANCH_TRANSACTION_LIST = `${MODULE}/get/branch/transction/list`;
export const GOT_BRANCH_TRANSACTION_LIST = `${MODULE}/got/branch/transction/list`;

export const GET_TRANSACTION_LIST = `${MODULE}/get_transaction_list`;
export const GOT_TRANSACTION_LIST = `${MODULE}/got_transaction_list`;

export const GET_CERTIFICATE_LIST = `${MODULE}/get_certificate_list`;
export const GOT_CERTIFICATE_LIST = `${MODULE}/got_certificate_list`;
