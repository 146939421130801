import { List, Map } from 'immutable';

export const GET_LIST = 'TRAINING/get_list';
export const GOT_LIST = 'TRAINING/got_list';

export const ADD_TRAINING = 'ADD_TRAINING';

// export const GET_VIEW = 'TRAINING/get_view';
// export const GOT_VIEW = 'TRAINING/got_view';

export const INIT_STATE = Map({
  list: List([]),
  pager: Map({
    total: 0,
    current: 0,
  }),
});
