import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
    loading,
    // alert, 
    // history, 
    services,
    watchApiReponse
} from '../../Utils';
import * as c from "./constant";

function* getHistory({ args }) {

    yield put(loading('HISTORY'));
    const response = yield call(services.get(`ste/payment`), args)
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const { data, meta } = response.data;

        yield put({
            type: `PAYMENT/got_history`,
            data,
            pager: meta
        })

    })
}


function* printPermit({ params, onSuccess }) {
    yield put(loading('PRINT_PERMIT'));
    const response = yield call(services.get(`ste/payment/${params.txnid}/permit`))
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const data = response.data;
        yield call(onSuccess, data)
    })
}

function* printOnlinePermit({ args, onSuccess }) {
    yield put(loading('PRINT_ONLINE_PERMIT'));
    const response = yield call(services.get(`ste/payment/schedule/exam/print_permit`), args)
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const data = response.data;
        yield call(onSuccess, data)
    })
}

export default function* profileSaga() {
    yield all([
        takeEvery(`PAYMENT/get_history`, getHistory),
        takeEvery(`PAYMENT/print_permit`, printPermit),
        takeEvery(`PAYMENT/print_online_permit`, printOnlinePermit)
    ])
}