export const GET_TRANSACTION_OPTION = "TRANSACTION/get_transaction_option";
export const GOT_TRANSACTION_OPTION = "TRANSACTION/got_transaction_option";

export const GET_TRANSACTION_DETAIL = "TRANSACTION/get_transaction_detail";
export const GOT_TRANSACTION_DETAIL = "TRANSACTION/got_transaction_detail";

export const PROCEED_TO_REQUIREMENTS = "TRANSACTION/proceed_to_requirements";
export const PROCEED_TO_APPOINTMENT = "TRANSACTION/proceed_to_appointment";

export const SET_TRANSACTION_DATA = "TRANSACTION/set_transaction_data";
export const GET_TRANSACTION_DATA = "TRANSACTION/get_transaction_data";

export const GET_PAYMENT_INFO = "TRANSACTION/GET_PAYMENT_INFO";
export const GOT_PAYMENT_INFO = "TRANSACTION/GOT_PAYMENT_INFO";

export const GET_CLASSES = "TRANSACTION/GET_CLASSES";
export const GOT_CLASSES = "TRANSACTION/GOT_CLASSES";

export const GET_DOCUMENTS = "TRANSACTION/GET_DOCUMENTS";
export const GOT_DOCUMENTS = "TRANSACTION/GOT_DOCUMENTS";

export const GET_TYPES = "TRANSACTION/GET_TYPES";
export const GOT_TYPES = "TRANSACTION/GOT_TYPES";

export const GET_BRANCH = "TRANSACTION/GET_BRANCH";
export const GOT_BRANCH = "TRANSACTION/GOT_BRANCH";

export const GET_VENUES = "TRANSACTION/GET_VENUES";
export const GOT_VENUES = "TRANSACTION/GOT_VENUES";

export const GET_REQUIREMENTS = "TRANSACTION/GET_REQUIREMENTS";
export const GOT_REQUIREMENTS = "TRANSACTION/GOT_REQUIREMENTS";

export const GET_QUALIFICATION = "TRANSACTION/GET_QUALIFICATION";
export const GOT_QUALIFICATION = "TRANSACTION/GOT_QUALIFICATION";
