import * as c from './constant';
import { Map, fromJS } from 'immutable';


const CLEAR_STATE = Map({
    is_vaccinated: '',
    vaccine: '',
    dose: '',
    first_dose_date: '',
    second_dose_date: ''
})
const INIT_STATE = Map({
    vaccination_record: Map([]),
    form_data: Map({
        is_vaccinated: '',
        vaccine: '',
        dose: '',
        first_dose_date: '',
        second_dose_date: ''
    }),
})

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case c.GOT_VACCINATION_RECORD:
            return state.set(`vaccination_record`, fromJS(action.data))
        case c.FORM_DATA:
            return state.update(`form_data`, form_data => form_data.merge(fromJS(action.data)));
        case c.CLEAR_FORM_DATA:
            return state.set(`form_data`, CLEAR_STATE);
        default:
            return state;
    }
}