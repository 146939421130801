import {
  loading
  // apiError
} from "./modules/common/action";
import {
  sleep,
  getFirstMessage,
  loadState,
  virtualizeHighlighter
} from "./Helpers";
import * as services from "./Services";
import alert from "react-s-alert";
import history from "./History";
import { call, put } from "redux-saga/effects";
import moment from "moment-timezone";
import AsyncComponent from "./AsyncComponent";
import _ from "lodash";
//import config from '../config/app';

function* watchApiReponse(
  response = {},
  responseOk = function*() {},
  responseNotOkay = function*() {}
) {
  if (response.status === 200) {
    yield call(responseOk);
  }

  if (response.status === 201) {
    yield call(responseOk);
  }

  if (response.status === 422) {
    alert.error(getFirstMessage(response.data.errors));
  }

  if (response.status === 403) {
    alert.error(response.data.message);
  }

  if (
    response.status === 403 &&
    response.data.message.toLowerCase().includes("token")
  ) {
    alert.error("Session Expired");
    yield put({
      type: "AUTH/CLEAR_STORAGE"
    });
  }

  if (response.status === 500) {
    alert.error("Oops! Something went wrong. Please contact web admin");
  }

  if (response.status === 400 || response.status === 404) {
    alert.error(response.data.message || getFirstMessage(response.data.errors));
    yield call(responseNotOkay);
  }
}

export {
  loading,
  sleep,
  alert,
  history,
  services,
  // apiError,
  watchApiReponse,
  moment,
  loadState,
  AsyncComponent,
  //config,
  virtualizeHighlighter,
  _
};
