import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
    loading,
    alert,
    history,
    services,
    watchApiReponse,
    // loadState,
    // _
} from '../../Utils';
import * as c from './constant';

function* getVaccinationRecord({ args }) {
    yield put(loading('GET_VACCINATION_RECORD'));

    const response = yield call(services.get(`ste/vaccination_record`), args)
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const { data } = response.data;

        yield put({
            type: c.GOT_VACCINATION_RECORD,
            data,
        })
    })

}

function* create({ args }) {
    yield put(loading('GET_VACCINATION_RECORD'));

    const response = yield call(services.post(`ste/vaccination_record`), args)
    yield put(loading(null));

    yield call(watchApiReponse, response, function* () {
        const { message } = response.data;
        alert.success(message)

        yield all([
            put({
                type: "MODAL",
                data: {
                    isOpen: false,
                    content: null,
                    title: "",
                    modalSize: "modal-md"
                }
            }),
            getVaccinationRecord({ args: {} }),
            history.push('/main/vaccination-record')
        ]);

    })

}

export default function* profileSaga() {
    yield all([
        takeEvery(c.GET_VACCINATION_RECORD, getVaccinationRecord),
        takeEvery(c.CREATE, create),
    ])
}