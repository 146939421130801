import { takeLatest, put, call, all } from 'redux-saga/effects';
import { loading, services, watchApiReponse, alert } from '../../Utils';
import _ from 'lodash';
import * as c from './constant';
import { takeEvery } from 'redux-saga';

function* getList({ id, args }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${id}/seafarer`;

  const response = yield call(services.get(url), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data, meta } = response.data;

    yield put({
      type: c.GOT_LIST,
      data,
      pager: meta,
    });
  });
}

function* getInstructor({ args }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/instructor`;

  const response = yield call(services.get(url), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT_INSTRUCTOR,
      data
    });
  });
}



function* getSeafarer({ srin, onSuccess }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/seafarer/${srin}`;

  const response = yield call(services.get(url), { });
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield call(onSuccess, data)
  });
}

function* attachSeafarer({ training_id,args, onSuccess }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${training_id}/seafarer`;

  const response = yield call(services.post(url),args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data, message } = response.data;
    alert.success(message)
    yield call(onSuccess, data)
    yield put({
      type: 'MODAL',
      data: {
        isOpen: false
      },
    })
    yield call(getList, { id: training_id, args: {} })
  });
}

function* addTraining({ args }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${args.id}/instructor`;

  const response = yield call(services.post(url), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const message = _.get(response, 'data.message', 'Training created successfully!');

    alert.success(message);

    yield all([
      put({
        type: 'MODAL',
        data: {
          isOpen: false,
          content: null,
          title: '',
          modalSize: 'modal-md',
        },
      }),
      getList({ id: args.id, args: {} }),
    ]);
  });
}

function* deleteTraining({ id, args }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${id}/seafarer/${args.id}`;

  const response = yield call(services.remove(url), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const message = _.get(response, 'data.message', 'Training created deleted!');

    alert.success(message);

    yield all([
      put({
        type: 'MODAL',
        data: {
          isOpen: false,
          content: null,
          title: '',
          modalSize: 'modal-md',
        },
      }),
      getList({ id: id, args: {} }),
    ]);
  });
}

function* edit({ args }) {

  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${args.id}/instructor`;

  const response = yield call(services.put(url), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { message } = response.data

    alert.success(message);

    yield all([
      put({
        type: 'MODAL',
        data: {
          isOpen: false,
          content: null,
          title: '',
          modalSize: 'modal-md',
        },
      }),
      getList({ args: {} }),
    ]);
  });
}

function* uploadAttachment(args) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${args.class_id}/seafarer/${args.seafarer_id}/upload`;

  const response = yield call(services.put(url), { url: args.url });

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const message = _.get(response, 'data.message', 'Successfully attached');

    alert.success(message);

    yield all([
      put({
        type: 'MODAL',
        data: {
          isOpen: false,
          content: null,
          title: '',
          modalSize: 'modal-md',
        },
      }),
      getList({ id: args.class_id, args: {} }),
    ]);
  });
}

function* tagComplete({ class_id, searfarer_id, date }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${class_id}/seafarer/${searfarer_id}/complete_class`;

  const response = yield call(services.put(url), { date_completed: date });

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const message = _.get(response, 'data.message', 'Successfully tag as completed');

    alert.success(message);

    yield all([
      put({
        type: 'MODAL',
        data: {
          isOpen: false,
          content: null,
          title: '',
          modalSize: 'modal-md',
        },
      }),
      getList({ id: class_id, args: {} }),
    ]);
  });
}

export default function* Saga() {
  yield all([
    takeLatest(c.GET_LIST, getList),
    takeLatest(c.ADD_TRAINING, addTraining),
    takeEvery(c.GET_SEAFARER, getSeafarer),
    takeEvery(c.ATTACH_SEAFARER, attachSeafarer),
    takeEvery(c.GET_INSTRUCTOR, getInstructor),
    takeEvery(c.EDIT, edit),
    takeEvery(c.DELETE_TRAINING, deleteTraining),
    takeEvery(c.UPLOAD_ATTACHMENT, uploadAttachment),
    takeEvery(c.TAG_COMPLETE, tagComplete)
  ]);
}
