import * as c from "./constant";
import { Map, List, fromJS } from "immutable";

const INIT_STATE = Map({
  transaction: List([]),
  details: Map({}),
  transactionData: Map({}),
  paymentInfo: Map({}),
  classes: List([]),
  documents: List([]),
  types: List([]),
  branches: List([]),
  venues: List([]),
  requirements: List([]),
  qualification: List([])
});

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case c.GOT_TRANSACTION_OPTION:
      return state.set(`transaction`, fromJS(action.data));
    case c.GOT_TRANSACTION_DETAIL:
      return state.set(`details`, fromJS(action.data));
    case c.SET_TRANSACTION_DATA:
      return state.set(`transactionData`, fromJS(action.args));
    case c.GOT_PAYMENT_INFO:
      return state.set(`paymentInfo`, fromJS(action.data));
    case c.GOT_CLASSES:
      return state.set(`classes`, fromJS(action.data));
    case c.GOT_DOCUMENTS:
      return state.set(`documents`, fromJS(action.data));
    case c.GOT_TYPES:
      return state.set(`types`, fromJS(action.data));
    case c.GOT_BRANCH:
      return state.set(`branches`, fromJS(action.data));
    case c.GOT_VENUES:
      return state.set(`venues`, fromJS(action.data));

    case c.GOT_REQUIREMENTS:
      return state.set(`requirements`, fromJS(action.data));
    case c.GOT_QUALIFICATION:
      return state.set(`qualification`, fromJS(action.data));

    default:
      return state;
  }
};
