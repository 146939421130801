import { takeLatest, put, call, all } from 'redux-saga/effects';
import { loading, services, watchApiReponse, alert } from '../../Utils';
//import _ from 'lodash';
import * as c from './constant';
import { takeEvery } from 'redux-saga';

function* getList({ args }) {
  yield put(loading(c.GET_LIST));

  const url = 'tcr/training_class';

  const response = yield call(services.get(url), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data, meta } = response.data;
    
    yield put({
      type: c.GOT_LIST,
      data,
      pager: meta,
    });
  });
}

function* get({ id, args }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${id}`;

  const response = yield call(services.get(url), args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT,
      data
    });
  });
}

function* getSeafarer({ srin, onSuccess }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/seafarer/${srin}`;

  const response = yield call(services.get(url), { });
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield call(onSuccess, data)
  });
}

function* attachSeafarer({ training_id,args, onSuccess }) {
  yield put(loading(c.GET_LIST));

  const url = `tcr/training/${training_id}/seafarer`;

  const response = yield call(services.post(url),args);
  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { data, message } = response.data;
    alert.success(message)
    yield call(onSuccess, data)
  });
}

function* addTraining({ args }) {
  yield put(loading(c.GET_LIST));

  const url = 'tcr/training_class';

  const response = yield call(services.post(url), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { message } = response.data

    alert.success(message);

    yield all([
      put({
        type: 'MODAL',
        data: {
          isOpen: false,
          content: null,
          title: '',
          modalSize: 'modal-md',
        },
      }),
      getList({ args: {} }),
    ]);
  });
}

function* editSchedule({ args }) {

  yield put(loading(c.GET_LIST));

  const url = `tcr/training_class/${args.id}`;

  const response = yield call(services.put(url), args);

  yield put(loading(null));

  yield call(watchApiReponse, response, function*() {
    const { message } = response.data

    alert.success(message);

    yield all([
      put({
        type: 'MODAL',
        data: {
          isOpen: false,
          content: null,
          title: '',
          modalSize: 'modal-md',
        },
      }),
      getList({ args: {} }),
    ]);
  });
}

export default function* Saga() {
  yield all([
    takeLatest(c.GET_LIST, getList),
    takeLatest(c.GET, get),
    takeLatest(c.ADD_TRAINING, addTraining),
    takeEvery(c.GET_SEAFARER, getSeafarer),
    takeEvery(c.ATTACH_SEAFARER, attachSeafarer),
    takeEvery(c.UPDATE_SCHEDULE, editSchedule)
  ]);
}
