const MODULE = 'SERVICE_RECORDS';

export const GET_SERVICE_RECORDS = `${MODULE}/get_service_records`;
export const GOT_SERVICE_RECORDS = `${MODULE}/got_service_records`;
export const FORM_DATA = `${MODULE}/form_data`;
export const CLEAR_FORM_DATA = `${MODULE}/clear_form_data`;
export const CREATE = `${MODULE}/create`;
export const UPDATE = `${MODULE}/update`;
export const REMOVE = `${MODULE}/remove`;
export const GET_DETAILS = `${MODULE}/get_details`;
export const GOT_DETAILS = `${MODULE}/got_details`;
