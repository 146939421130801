import { takeEvery, put, call, all } from 'redux-saga/effects';
import { loading, watchApiReponse, services, alert, _ } from '../../Utils';
import * as c from './constant';

function* editTrainingCenterProfile({ args, onSuccess }) {
	
	yield put(loading('EDIT_TRAINING_CENTER_PROFILE'))

	const url = `tcr/profile`;

	const response = yield call(services.put(url), args );
	
	yield put(loading(null));

	yield call(watchApiReponse, response, function*() {
		const { message, data } = response.data	

		alert.success(message)

		yield all([
			put({
				type: c.GOT_TRAINING_CENTER_PROFILE,
				data
			}),
			put({
				type: c.SET_FORM_DATA,
				data: _.pick(data, _.keys(c.FORM_DATA))
			}),
		])

		if(onSuccess){
			onSuccess();
		}
		
	});
}

export default function*() {
	yield all([ 
		takeEvery(c.EDIT_TRAINING_CENTER_PROFILE, editTrainingCenterProfile),
	])
}