import { List, Map } from 'immutable';

export const GET_LIST = 'ASSESSMENT/get_list';
export const GOT_LIST = 'ASSESSMENT/got_list';

export const ADD_TRAINING = 'ASSESSMENT_ADD_TRAINING_SCHEDULE_INSTRUCTOR';

export const DELETE_TRAINING = 'ASSESSMENT/delete';

export const EDIT = 'ASSESSMENT_EDIT_TRAINING_SCHEDULE_INSTRUCTOR';

export const GET_SEAFARER = 'ASSESSMENT/get_seafarer'
export const GOT_SEAFARER = 'ASSESSMENT/get_seafarer/success'

export const ATTACH_SEAFARER = 'ASSESSMENT/attach/seafarer'

export const ATTACH_SEAFARER_SUCCESS = 'ASSESSMENT/attach/seafarer/success'

export const UPLOAD_ATTACHMENT = 'ASSESSMENT/upload_attachment'
// export const GET_VIEW = 'TRAINING/get_view';
// export const GOT_VIEW = 'TRAINING/got_view';

export const GET_INSTRUCTOR = 'GET_INSTRUCTOR';
export const GOT_INSTRUCTOR = 'GOT_INSTRUCTOR';

export const TAG_COMPLETE = 'ASSESSMENT_SEAFARER/tag/complete';
export const TAG_COMPLETE_SUCCESS = 'ASSESSMENT_SEAFARER/tag/complete/success';

export const INIT_STATE = Map({
  list: List([]),
  pager: Map({
    total: 0,
    current: 0,
  }),
  instructors: List([])
});
