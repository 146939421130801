import { takeEvery, put, call, all, takeLatest } from "redux-saga/effects";
import { loading, watchApiReponse, services } from "../../Utils";
import * as c from "./constant";

function* getList({ args, branch_id, transaction_id }) {
  yield put(loading(c.GET_LIST));
  const url = `ste/branch/${branch_id}/appointment?paginate=0&transaction_id=${transaction_id}`;

  const response = yield call(services.get(url), args);

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT_LIST,
      data
    });
  });

  yield put(loading(c.GET_LIST, false));
}

function* getBranchList() {
  yield put(loading("GET_BRANCH"));

  const url = `ste/branch`;
  const response = yield call(services.get(url), {});

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT_BRANCH_LIST,
      data
    });
  });

  yield put(loading("GET_BRANCH", false));
}

function* getBranchTransactionList({ branch_id }) {
  yield put(loading("GET_BRANCH_TRANSACTION_LIST"));

  const url = `ste/branch/${branch_id}/transaction`;
  const response = yield call(services.get(url), {});

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT_BRANCH_TRANSACTION_LIST,
      data
    });
  });

  yield put(loading("GET_BRANCH_TRANSACTION_LIST", false));
}

function* getTransactionList() {
  yield put(loading(c.GET_TRANSACTION_LIST));

  const url = `ste/application/transaction`;
  const response = yield call(services.get(url), {});

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT_TRANSACTION_LIST,
      data
    });
  });

  yield put(loading(c.GET_TRANSACTION_LIST, false));
}

function* getCertificateList({ args }) {
  yield put(loading(c.GET_CERTIFICATE_LIST));

  const url = `ste/appointment`;
  const response = yield call(services.get(url), args);

  yield call(watchApiReponse, response, function*() {
    const { data } = response.data;

    yield put({
      type: c.GOT_CERTIFICATE_LIST,
      data
    });
  });

  yield put(loading(c.GET_CERTIFICATE_LIST, false));
}

export default function* Saga() {
  yield all([
    takeEvery(c.GET_LIST, getList),
    takeLatest(c.GET_BRANCH_LIST, getBranchList),
    takeLatest(c.GET_BRANCH_TRANSACTION_LIST, getBranchTransactionList),
    takeLatest(c.GET_TRANSACTION_LIST, getTransactionList),
    takeEvery(c.GET_CERTIFICATE_LIST, getCertificateList)
  ]);
}
