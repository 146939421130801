import { Map, fromJS, List } from 'immutable';
import * as c from './constant';

const initState = Map({
	documents_list: List([]),
	form_data: Map(c.FORM_DATA),
	selected_document: Map({})
})

export default (state = initState, action) => {
    switch(action.type) {
        case c.GOT_STE_DOCUMENT:
            return state.set(`documents_list`, fromJS(action.data))

        case c.SET_FORM_DATA:
			return state.update('form_data',form_data => form_data.merge(fromJS(action.data)));

		case c.GOT_SELECTED_ATTACH_FILE:
			return state.set(`selected_document`, fromJS(action.data))
		
		case c.CLEAR_FORM_DATA:
			return state.set('form_data', initState.get('form_data'));
			
        default:
            return state;
    }
}