import { List, Map } from 'immutable';

export const GET_LIST = 'SEAFARER_ASSESSMENT/get_list';
export const GOT_LIST = 'SEAFARER_ASSESSMENT/got_list';

export const ADD_TRAINING = 'SEAFARER_ADD_ASSESSMENT';

export const GET_LIST_THEORETICAL = 'SEAFARER_ASSESSMENT/get_list_theoretical';
export const GOT_LIST_THEORETICAL = 'SEAFARER_ASSESSMENT/got_list_theoretical';

// export const GET_VIEW = 'TRAINING/get_view';
// export const GOT_VIEW = 'TRAINING/got_view';

export const INIT_STATE = Map({
  list: List([]),
  pager: Map({
    total: 0,
    current: 0,
  }),
});
