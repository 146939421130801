import * as c from "./constant";
import { Map, List, fromJS } from "immutable";

const INIT_STATE = Map({
  venues: List([]),
  form_data: Map({
    keyword: "",
    type: "",
    venue_id: "",
    department_id: "",
    exam_id: "",
    exam_schedule_id: ""
  }),
  examination_schedules: List([]),
  transaction: Map({})
});

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case c.GOT_VENUES:
      return state.set("venues", fromJS(action.data));

    case c.SET_FORM_DATA:
      return state.update("form_data", form_data =>
        form_data.merge(Map(action.data))
      );

    case c.GOT_EXAMINATION_SCHEDULE_LIST:
      return state.set("examination_schedules", fromJS(action.data));

    case c.GOT_TRANSACTION:
      return state.set(`transaction`, fromJS(action.data));

    default:
      return state;
  }
};
